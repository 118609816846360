import {Grid} from "react-virtualized";
import {ratio} from "../Card";
import React from "react";
import {Box} from "@chakra-ui/react";

export default function CardListing({height, width, cardRenderer, items}) {
  let columnCount = 6;

  if (width > 1300) {
    columnCount = 6;
  } else if (width > 1100) {
    columnCount = 5;
  } else if (width > 900) {
    columnCount = 4;
  } else if (width > 700) {
    columnCount = 3;
  } else if (width > 500) {
    columnCount = 2;
  } else if (width > 400) {
    columnCount = 1;
  }

  const columnWidth = (width - 16) / columnCount;

  const cellRenderer = ({columnIndex, key, rowIndex, style}) => {
    const index = (rowIndex * columnCount) + columnIndex;
    const item = items[index];

    if (item) {
      return (
        <Box key={key} style={style} p={4}>
          {cardRenderer(item)}
        </Box>
      )
    }

    return null;
  };

  const noContentRenderer = () => {

  };

  return (
    <Grid
      tabIndex={null}
      cellRenderer={cellRenderer}
      columnWidth={columnWidth}
      noContentRenderer={noContentRenderer}
      height={height}
      width={width}
      rowHeight={columnWidth / ratio}
      rowCount={Math.ceil(items.length / columnCount)}
      columnCount={columnCount}
    />
  )
}
