import {AspectRatio, Box} from "@chakra-ui/react"
import React from "react";
import MultipleCoverArt from "./MultipleCoverArt";
import {Link as RouterLink} from "react-router-dom";
import CoverArt from "./CoverArt";

export const ratio = 0.8;

export default function Card({to, albumId, songs, children, ...props}) {
  return (
    <AspectRatio {...props} ratio={ratio}>
      <Box flexDirection={'column'} alignItems={'flex-start'} justifyContent={'flex-start'}>
        <Box as={RouterLink} to={to} width={'100%'} boxShadow={'0 0 11px 2px #00000014'} borderRadius={'sm'} overflow={'hidden'}>
          {albumId && (
            <CoverArt
              borderRadius={"sm"}
              albumId={albumId}
            />
          )}
          {songs && (
            <MultipleCoverArt
              borderRadius={"sm"}
              songs={songs}
            />
          )}
        </Box>
        <Box pt={2} width={'100%'}>
          {children}
        </Box>
      </Box>
    </AspectRatio>
  )
}
