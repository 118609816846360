import React from 'react';

export default function LogoSvg(props) {
  return (
    <svg viewBox="0 0 506.581 506.581" {...props}>
      <path d="M74.738 179.104a7.5 7.5 0 00-10.195 2.927c-15.116 27.286-23.105 58.281-23.105 89.634 0 102.148 83.104 185.252 185.252 185.252 23.036 0 45.532-4.188 66.864-12.447a7.5 7.5 0 00-5.416-13.988c-19.598 7.588-40.271 11.436-61.448 11.436-93.877 0-170.252-76.375-170.252-170.252 0-28.815 7.34-57.298 21.227-82.366a7.5 7.5 0 00-2.927-10.196zM89.04 166.698a7.485 7.485 0 005.81-2.751c32.509-39.741 80.563-62.534 131.84-62.534 18.949 0 37.569 3.097 55.347 9.204a7.5 7.5 0 009.529-4.656 7.498 7.498 0 00-4.656-9.529c-19.348-6.647-39.609-10.019-60.22-10.019-55.795 0-108.081 24.798-143.451 68.036a7.499 7.499 0 001.057 10.554 7.461 7.461 0 004.744 1.695zM403.459 260.456a7.5 7.5 0 007.468-8.281c-4.219-40.311-21.142-77.323-48.941-107.037a7.5 7.5 0 00-10.954 10.248c25.548 27.308 41.101 61.316 44.977 98.35a7.501 7.501 0 007.45 6.72z"/>
      <path d="M350.526 334.105a7.499 7.499 0 009.881-3.855c8.126-18.521 12.246-38.232 12.246-58.585 0-80.483-65.479-145.962-145.963-145.962S80.728 191.182 80.728 271.665c0 80.484 65.479 145.963 145.962 145.963 15.65 0 31.068-2.473 45.827-7.351a7.501 7.501 0 00-4.707-14.243c-13.237 4.375-27.072 6.593-41.12 6.593-72.213 0-130.962-58.75-130.962-130.963s58.75-130.962 130.962-130.962 130.963 58.749 130.963 130.962c0 18.265-3.695 35.948-10.982 52.56a7.498 7.498 0 003.855 9.881z"/>
      <path d="M336.35 271.664c0-60.467-49.193-109.66-109.66-109.66s-109.66 49.193-109.66 109.66c0 19.291 5.084 38.268 14.704 54.879a7.497 7.497 0 0010.249 2.731 7.5 7.5 0 002.732-10.249c-8.298-14.329-12.684-30.707-12.684-47.361 0-52.195 42.464-94.66 94.66-94.66 52.195 0 94.66 42.465 94.66 94.66s-42.465 94.66-94.66 94.66c-23.427 0-45.911-8.627-63.309-24.292a7.501 7.501 0 00-10.036 11.149c20.157 18.148 46.206 28.144 73.345 28.144 60.466-.001 109.659-49.194 109.659-109.661z"/>
      <path d="M275.957 218.463a7.501 7.501 0 00-1.488-10.502c-13.887-10.439-30.408-15.957-47.778-15.957-43.925 0-79.66 35.735-79.66 79.66s35.735 79.66 79.66 79.66 79.66-35.735 79.66-79.66a79.72 79.72 0 00-9.017-36.753 7.5 7.5 0 00-13.297 6.943c4.853 9.293 7.313 19.322 7.313 29.81 0 35.653-29.007 64.66-64.66 64.66-35.654 0-64.66-29.007-64.66-64.66s29.006-64.66 64.66-64.66c14.093 0 27.498 4.477 38.765 12.947a7.502 7.502 0 0010.502-1.488z"/>
      <path d="M193.379 271.664c0 18.368 14.943 33.312 33.311 33.312S260 290.032 260 271.664s-14.943-33.312-33.311-33.312-33.31 14.944-33.31 33.312zm51.622 0c0 10.097-8.214 18.312-18.311 18.312s-18.311-8.215-18.311-18.312 8.214-18.312 18.311-18.312 18.311 8.216 18.311 18.312z"/>
      <path d="M503.995 79.067a17.416 17.416 0 00-10.852-7.894l-24.574-5.913a17.417 17.417 0 00-13.255 2.092 17.419 17.419 0 00-7.894 10.852l-12.668 52.645c-2.261 9.399 3.546 18.887 12.944 21.147l4.954 1.192-12.581 41.629c-11.086-30.836-28.894-59.125-52.683-83.034a7.5 7.5 0 00-10.606-.026 7.498 7.498 0 00-.026 10.606c27.474 27.613 46.408 61.48 55.483 98.371L410.43 292.89a3.395 3.395 0 01-.764 1.327l-57.299 61.584-5.466-5.625a17.676 17.676 0 00-14.576-5.295 17.683 17.683 0 00-13.189 8.159l-23.794 37.488a17.725 17.725 0 002.262 21.924l6.36 6.544a17.729 17.729 0 0012.74 5.405c3.119 0 6.266-.824 9.109-2.518l38.153-22.713a17.682 17.682 0 008.532-12.951 17.68 17.68 0 00-4.876-14.722l-4.788-4.927 57.813-62.136a18.488 18.488 0 004.143-7.209l12.9-42.683a215.72 215.72 0 01.691 17.121c0 116.727-94.964 211.69-211.69 211.69C109.964 483.355 15 388.391 15 271.664s94.964-211.69 211.69-211.69c28.1 0 55.409 5.427 81.167 16.131a7.498 7.498 0 009.804-4.048 7.5 7.5 0 00-4.048-9.804c-27.592-11.466-56.837-17.279-86.923-17.279-60.551 0-117.478 23.58-160.294 66.396C23.58 154.186 0 211.113 0 271.664s23.58 117.479 66.396 160.294c42.816 42.816 99.743 66.396 160.294 66.396 60.552 0 117.479-23.58 160.294-66.396 42.816-42.815 66.396-99.742 66.396-160.294 0-17.189-1.909-34.089-5.615-50.46l19.494-64.501 5.011 1.206h.001c1.373.33 2.747.488 4.101.488 7.915 0 15.116-5.407 17.047-13.433l12.668-52.645a17.41 17.41 0 00-2.092-13.252zM357.627 384.256a2.734 2.734 0 01-1.335 2.027l-38.152 22.713a2.775 2.775 0 01-3.42-.453l-6.36-6.544a2.774 2.774 0 01-.354-3.431L331.8 361.08a2.736 2.736 0 012.064-1.277 2.713 2.713 0 012.28.828l20.721 21.321c.817.842.829 1.805.762 2.304zM491.503 88.812l-12.668 52.645a2.538 2.538 0 01-3.055 1.869l-24.575-5.914a2.535 2.535 0 01-1.869-3.055l12.668-52.645a2.516 2.516 0 011.14-1.566 2.52 2.52 0 011.915-.302l24.575 5.913c.887.214 1.362.806 1.567 1.14s.516 1.027.302 1.915z"/>
      <path d="M309.031 125.471c3.614 5.638 9.904 9.241 17.257 9.886.816.072 1.64.107 2.466.107 5.986 0 12.152-1.865 17.606-5.362 6.207-3.979 10.879-9.679 13.153-16.048 1.044-2.922 1.527-5.892 1.475-8.771.001-.053.008-.104.008-.157V75.823l20.647 20.647a11.14 11.14 0 0012.174 2.421 11.136 11.136 0 006.895-10.318V51.542c0-2.985-1.163-5.791-3.271-7.897l-32.094-32.094a11.305 11.305 0 00-12.354-2.457 11.304 11.304 0 00-6.997 10.473v65.896a25.56 25.56 0 00-5.521-1.103c-6.733-.588-13.866 1.274-20.073 5.255-13.427 8.609-18.528 24.694-11.371 35.856zm76.681-46.146l-24.716-24.717V28.415l24.716 24.715zm-57.214 22.916c3.007-1.928 6.416-2.988 9.526-2.988.385 0 .766.017 1.14.049 1.579.139 4.451.718 5.94 3.04 1.488 2.322.816 5.174.283 6.667-1.147 3.214-3.744 6.299-7.123 8.466-3.379 2.166-7.27 3.239-10.666 2.939-1.58-.139-4.451-.718-5.94-3.039-2.602-4.057.594-11.129 6.84-15.134z"/>
    </svg>
  )
}
