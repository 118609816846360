import React from "react";
import {BrowserRouter as Router, Route, Switch, useRouteMatch} from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import routes from './routes';

function WrappedComponent({Component}) {
  const match = useRouteMatch();

  return <Component match={match}/>
}

export default function App() {
  return (
    <Router>
      <Switch>
        {routes.map(routeGroup => {
          const LayoutComponent = routeGroup.layout || React.Fragment;
          const RouteComponent = routeGroup.public ? Route : PrivateRoute;
          const paths = routeGroup.routes.map(route => route.path);

          return (
            <RouteComponent path={paths} exact key={JSON.stringify(routeGroup)}>
              <LayoutComponent>
                <Switch>
                  {routeGroup.routes.map(route => {
                    const Component = route.component;

                    return (
                      <Route
                        key={JSON.stringify(route)}
                        exact
                        path={route.path}
                      >
                        <WrappedComponent Component={Component}/>
                      </Route>
                    )
                  })}
                </Switch>
              </LayoutComponent>
            </RouteComponent>
          )
        })}
      </Switch>
    </Router>
  )
}
