import React, {useState} from 'react';
import PlayerIcon from "./PlayerIcon";
import {Flex, Slider, SliderFilledTrack, SliderThumb, SliderTrack} from "@chakra-ui/react";
import constants from "../../styles/constants";

export default function PlayerIconWithVerticalSlider({PlayerIconProps, SliderProps, children, ContainerProps}) {
  const [isShown, setIsShown] = useState(false);
  const [isSliding, setIsSliding] = useState(false);

  const onChangeStart = () => {
    setIsSliding(true)
  };

  const onChangeEnd = () => {
    setIsSliding(false)
  };

  return (
    <Flex
      onMouseEnter={() => setIsShown(true)}
      onMouseLeave={() => setIsShown(false)}
      alignItems={"center"}
      mr={2}
      position={'relative'}
      {...ContainerProps}
    >
      <PlayerIcon {...PlayerIconProps}/>
      {children}
      {(isShown || isSliding) && (
        <Flex
          alignItems={"center"}
          position={'absolute'}
          bottom={'100%'}
          left={-2}
          py={6}
          px={4}
          backgroundColor={'white'}
          boxShadow="lg"
          borderWidth={1}
        >
          <Slider
            orientation={'vertical'}
            color={"red.100"}
            height={200}
            onChangeStart={onChangeStart}
            onChangeEnd={onChangeEnd}
            {...SliderProps}
          >
            <SliderTrack bg={constants.themeColorBg}>
              <SliderFilledTrack bg={constants.themeColor}/>
            </SliderTrack>
            <SliderThumb bg={constants.themeColor}/>
          </Slider>
        </Flex>
      )}
    </Flex>
  )
}
