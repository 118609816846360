import React from 'react';
import {Box, Button, Text} from "@chakra-ui/react";

function ContextMenuOption({label, onClick, setOpen, children}) {
  const clickHandler = (event) => {
    onClick(event);
    setOpen && setTimeout(() => setOpen(false))
  };

  return (
    <Button
      p={6}
      py={6}
      minWidth={'140px'}
      borderRadius={0}
      onClick={clickHandler}
      bg={'white'}
      borderWidth={0}
      borderColor={'white'}
      _hover={{
        backgroundColor: 'gray.50'
      }}
    >
      {!!label && (
        <Text>{label}</Text>
      )}
      {!!children && children}
    </Button>
  )
}

export default ContextMenuOption;
