import {useDocumentEvent} from '@huse/document-event';

export function useClickOutside(ref, callback) {
  let element = null;

  if (ref) {
    if (typeof ref.current !== 'undefined') {
      element = ref.current;
    } else {
      element = ref;
    }
  }

  const testAndTrigger = (e) => {
    if (element && element.contains && !element.contains(e.target)) {
      callback(e);
    }
  };

  useDocumentEvent('mouseup', testAndTrigger, true);
  useDocumentEvent('touchstart', testAndTrigger, true);
}
