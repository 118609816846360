export default (state = {
  items: false,
  albums: false,
  playlists: false,
}, action) => {
  switch (action.type) {
    case 'SET_SONGS':
      return {
        ...state,
        items: action.payload,
      };
    case 'PATCH_MULTIPLE_SONGS':
      return patchMultipleSongs(state, action);
    case 'DELETE_SONG':
      return {
        ...state,
        items: state.items.filter(song => song.id !== action.payload.id),
      };
    case 'SET_ALBUMS':
      return {
        ...state,
        albums: action.payload,
      };
    case 'SET_PLAYLISTS':
      return {
        ...state,
        playlists: action.payload,
      };
    case 'APPEND_PLAYLISTS':
      return {
        ...state,
        playlists: [...state.playlists, action.payload],
      };
    case 'APPEND_SONG':
      return {
        ...state,
        items: [...state.items, action.payload],
      };
    case 'DELETE_PLAYLIST':
      return {
        ...state,
        playlists: state.playlists.filter(playlist => {
          return playlist.id !== action.payload.id;
        }),
      };
    case 'PATCH_MULTIPLE_PLAYLISTS':
      return patchMultiplePlaylists(state, action);
    default:
      return state;
  }
}

function patchMultipleSongs(state, action) {
  let songs = [...state.items];

  action.payload.forEach(modelToPatch => {
    let index = songs.findIndex(row => row.id === modelToPatch.id);

    if (index !== -1) {
      songs[index] = {...songs[index], ...modelToPatch}
    }
  });

  return {
    ...state,
    items: songs
  };
}

function patchMultiplePlaylists(state, action) {
  let playlists = [...state.playlists];

  action.payload.forEach(modelToPatch => {
    let index = playlists.findIndex(row => row.id === modelToPatch.id);

    if (index !== -1) {
      playlists[index] = {...playlists[index], ...modelToPatch}
    }
  });

  return {
    ...state,
    playlists: playlists
  };
}
