import React, {useEffect, useState} from 'react';
import ReactAnsi from "react-ansi";
import io from "socket.io-client";
import {Box, CloseButton, Flex, Input} from "@chakra-ui/react"

var decoder = new TextDecoder("utf-8");
var beetsLogging = '';
let socket = null;

function BeetsConsole({onClose}) {
  const [beetsLog, setBeetsLog] = useState('');
  const [consoleText, setConsoleText] = useState('');
  const [ended, setEnded] = useState('');

  const onKeyDown = (evt) => {
    if (evt.key === 'Enter') {
      if (socket) {
        console.log(socket)

        beetsLogging = beetsLogging + `${consoleText}\n`;

        socket.emit('input-beets', {
          text: consoleText
        })

        setConsoleText('')
      }
    }
  };

  useEffect(() => {
    socket = io(`${window.location.protocol}//api.${window.location.hostname}`);

    socket.on('connect', function () {
      console.log('Connected');

      socket.emit('start-beets');
    });

    socket.on('beets-stdout', function (data) {
      const message = data.data;
      const text = decoder.decode(new Uint8Array(message));

      beetsLogging = beetsLogging + text;

      setBeetsLog(beetsLogging);

      console.log('beets-stdout', text);
    });

    socket.on('beets-stderr', function (data) {
      console.log('beets-stderr', data.data.toString());
    });

    socket.on('beets-ended', function () {
      beetsLogging = beetsLogging + 'Beets Ended.\n';

      setBeetsLog(beetsLogging);

      setEnded(true);
    });

    socket.on('disconnect', function () {
      console.log('Disconnected');
    });

    return () => socket.disconnect()
  }, [1]);

  return (
    <Flex
      flexDirection={"column"}
      zIndex={10}
      p={10}
      position={"absolute"}
      backgroundColor={'#222222'}
      top={0} right={0} bottom={0} left={0}>
      <CloseButton
        position={"absolute"}
        top={0}
        right={0}
        color={'red.500'}
        onClick={onClose}
      />
      <Flex
        pb={10}
        flex={1}
      >
        <Box
          flex={1}
          position={"relative"}
        >
          <ReactAnsi
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
            }}
            bodyStyle={{
              height: '100%',
              overflow: 'auto',
            }}
            log={beetsLog}
            autoScroll={true}
            linkify={true}
          />
        </Box>
      </Flex>
      <Input
        value={consoleText}
        onChange={evt => setConsoleText(evt.target.value)}
        onKeyDown={onKeyDown}
        backgroundColor={"gray.800"}
        border={"none"}
        color={"white"}
        isDisabled={ended}
      />
    </Flex>
  )
}

export default BeetsConsole;
