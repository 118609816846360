import React, {useEffect, useState} from 'react';
import {Flex, Slider, SliderFilledTrack, SliderThumb, SliderTrack, Text} from "@chakra-ui/react";
import constants from "../../styles/constants";

function getTime(timer) {
  const seconds = timer / 1000;

  const minutes = Math.floor(seconds / 60);

  let secondsLeft = Math.round(seconds - (minutes * 60)).toString()

  if (secondsLeft.length === 1) {
    secondsLeft = "0" + secondsLeft;
  }

  return `${minutes}:${secondsLeft}`;
}

export default function SeekControl({duration, position, setPosition}) {
  const [focused, setFocused] = useState(false);
  const [value, setValue] = useState(0);

  useEffect(() => {
    if (!focused) {
      setValue(position)
    }
  }, [position, focused])

  return (
    <Flex flex={1} mr={4} alignItems={"center"}>
      <Text fontSize="xs" color={constants.themeColor} mr={2}>{getTime(position)}</Text>
      <Slider
        min={0}
        max={duration || 0}
        value={value}
        onChange={setValue}
        size={"sm"}
        focusThumbOnChange={false}
        onMouseDown={() => setFocused(true)}
        onMouseUp={() => {
          setFocused(false)
          setPosition(value)
        }}
      >
        <SliderTrack bg={constants.themeColorBg}>
          <SliderFilledTrack bg={constants.themeColor}/>
        </SliderTrack>
        <SliderThumb bg={constants.themeColor}/>
      </Slider>
      <Text fontSize={"xs"} ml={2}>{getTime(duration)}</Text>
    </Flex>
  )
}
