import {Link as RouterLink} from "react-router-dom";
import React from "react";
import Card from "../../components/Card";
import { Link } from "@chakra-ui/react"

export const ratio = 0.8;

export default function AlbumItem({album, ...props}) {
  const albumLink = `/albums/${album.id}`;
  const artistLink = `/artists/${encodeURIComponent(album.albumartist)}`;

  return (
    <Card albumId={album.id} to={albumLink} {...props}>
      <>
        <Link
          display={"block"}
          isTruncated
          as={RouterLink}
          to={albumLink}
          fontWeight={"semibold"}
        >
          {album.album}
        </Link>
        <Link
          display={"block"}
          isTruncated
          as={RouterLink}
          to={artistLink}
          color={"gray.500"}
          letterSpacing="wide"
        >
          {album.albumartist}
        </Link>
      </>
    </Card>
  )
}
