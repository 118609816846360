import React, {useRef, useState} from "react";
import {connect} from "react-redux";
import Head from "../../components/Head";
import PlaylistListing from "./PlaylistListing";
import {Formik} from "formik";
import TextInput from "../../components/TextInput";
import {gql, useMutation} from "@apollo/client";
import {usePlaylists} from "music-app-shared";
import * as yup from 'yup';
import {AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Button} from "@chakra-ui/react";
import _ from 'lodash';

const mapStateToProps = state => {
  return {
    playlists: state.songs.playlists,
    songs: state.songs.items,
  };
};

const CREATE_PLAYLIST = gql`
  mutation CreatePlaylist($name: String!) {
    createPlaylist(name: $name) {
      id
      name
      song_ids
    }
  }
`;


function PlaylistsPage({dispatch, playlists: libraryPlaylists, songs: librarySongs}) {
  const [showCreation, setShowCreation] = useState(false);
  const cancelRef = useRef();
  const [createPlaylist] = useMutation(CREATE_PLAYLIST);

  console.log(libraryPlaylists)

  const playlists = usePlaylists(libraryPlaylists, librarySongs);

  const clickCreate = () => {
    setShowCreation(true)
  };

  const clickClose = () => {
    setShowCreation(false)
  };

  const onSubmit = (values) => {
    return createPlaylist({
      variables: {
        name: values.name,
      }
    })
      .then(response => {
        dispatch({
          type: 'APPEND_PLAYLISTS',
          payload: response.data.createPlaylist
        });
        clickClose();
      })
  };

  return (
    <>
      <Head title={"Playlists"}/>
      <PlaylistListing playlists={playlists}/>
      <Button
        position={"absolute"}
        colorScheme={"green"}
        bottom={10}
        right={10}
        onClick={clickCreate}>Create</Button>
      <AlertDialog
        isOpen={showCreation}
        leastDestructiveRef={cancelRef}
        onClose={clickClose}
      >
        <AlertDialogOverlay/>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            Create Playlist
          </AlertDialogHeader>
          <Formik
            onSubmit={onSubmit}
            initialValues={{
              name: '',
            }}
            validationSchema={yup.object({
              name: yup.string().required()
            })}
          >
            {form => {
              return (
                <>
                  <AlertDialogBody>
                    <TextInput label="Name" name="name"/>
                  </AlertDialogBody>
                  <AlertDialogFooter>
                    <Button ref={cancelRef} onClick={clickClose}>
                      Cancel
                    </Button>
                    <Button colorScheme="green" onClick={form.submitForm} ml={3}>
                      Create
                    </Button>
                  </AlertDialogFooter>
                </>
              )
            }}
          </Formik>
        </AlertDialogContent>
      </AlertDialog>
    </>
  )
}


export default connect(mapStateToProps)(PlaylistsPage)
