import {Link as RouterLink} from "react-router-dom";
import React from "react";
import {AspectRatio, Avatar, Flex, Link} from "@chakra-ui/react"

export const ratio = 1;

export default function ArtistItem({artist, ...props}) {
  return (
    <AspectRatio {...props} ratio={ratio}>
      <Flex flexDirection={"column"} alignItems={"center"} justifyContent={"center"}>
        <Avatar name={artist} size={"2xl"} showBorder mb={2}/>
        <Link
          isTruncated
          width={"100%"}
          as={RouterLink}
          textAlign={"center"}
          to={`/artists/${encodeURIComponent(artist)}`}
        >
          {artist}
        </Link>
      </Flex>
    </AspectRatio>
  )
}
