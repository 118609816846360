export default (state = {
  files: [],
}, action) => {
  switch (action.type) {
    case 'SET_UPLOADING_FILES':
      return {
        ...state,
        files: action.payload,
      };
    case 'ADD_UPLOADING_FILES':
      return {
        ...state,
        files: [
          ...state.files,
          ...action.payload,
        ],
      };
    case 'PATCH_UPLOADING_FILE':
      return {
        ...state,
        files: state.files.map(file => {
          if (file.id === action.payload.id) {
            return {
              ...file,
              ...action.payload,
            }
          } else {
            return file;
          }
        }),
      };
    default:
      return state;
  }
}
