import React, {useState} from 'react';
import {usePopper} from "react-popper";
import {Box} from "@chakra-ui/react";

function ContextMenuContainer({view, children}) {
  const [open, setOpen] = useState(false);

  const onClick = () => {
    setOpen(true);
  };

  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const {styles, attributes} = usePopper(referenceElement, popperElement, {
    placement: 'left',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 0],
        },
      },
    ],
  });

  return (
    <Box
      position={"relative"}
      onClick={onClick}
      ref={setReferenceElement}
    >
      {view}
      {open && React.cloneElement(children, {setOpen, popperElement, setPopperElement, styles, attributes})}
    </Box>
  )
}

const Styles = {};

export default ContextMenuContainer;
