import React from 'react';
import {MdTimer} from "react-icons/md";
import {Text} from "@chakra-ui/react";
import PlayerIconWithVerticalSlider from "./PlayerIconWithVerticalSlider";

export default function PlaybackRateControl({playbackRate, setPlaybackRate,}) {
  return (
    <PlayerIconWithVerticalSlider
      PlayerIconProps={{
        icon: MdTimer,
      }}
      SliderProps={{
        min: 0.5,
        max: 4,
        step: 0.1,
        value: playbackRate,
        onChange: setPlaybackRate,
      }}
      ContainerProps={{
        justifyContent: 'center',
      }}
    >
      <Text
        position={'absolute'}
        bottom={'7px'}
        left={'8px'}
        mr={4}
        fontSize={8}
        fontWeight={'bold'}
        backgroundColor={'white'}
      >{parseFloat(playbackRate).toFixed(1)}</Text>
    </PlayerIconWithVerticalSlider>
  );
}
