import React from "react";
import {connect} from "react-redux";
import {Redirect} from "react-router-dom";

function RootPage() {
  return (
    <Redirect to={'/playlists'}/>
  )
}


export default connect()(RootPage)
