import React, {useState} from "react";
import {FaHeart} from "react-icons/fa";
import {connect} from "react-redux";
import {gql, useMutation} from "@apollo/client";
import {Box} from "@chakra-ui/react";

const SET_SONG_LIKED = gql`
  mutation SetSongLiked($id: Int!, $liked_at: Int!) {
    setSongLiked(id: $id, liked_at: $liked_at) {
      id
      liked_at
    }
  }
`;

function RatingColumn({cell, value, dispatch}) {
  const [hover, setHover] = useState(false);
  const [setSongLiked] = useMutation(SET_SONG_LIKED);

  const isFav = !!value;

  const onClick = () => {
    setSongLiked({
      variables: {
        id: cell.row.original.id,
        liked_at: isFav ? 0 : Math.round((new Date().getTime() / 1000)),
      }
    })
      .then(response => {
        dispatch({
          type: 'PATCH_MULTIPLE_SONGS',
          payload: [response.data.setSongLiked],
        })
      })
  };

  return (
    <Box as={"button"} p={1}
         onMouseEnter={() => setHover(true)}
         onMouseLeave={() => setHover(false)}
         onClick={onClick}
    >
      <Box as={FaHeart}
           size="18px"
           color={hover ? 'red.200' : isFav ? 'red.400' : 'transparent'}
      />
    </Box>
  );
}

export default connect()(RatingColumn)
