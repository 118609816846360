import React from 'react';
import {CheckCircleIcon, WarningIcon} from "@chakra-ui/icons";
import {Box, Flex, Progress, Spinner, Stack, Text} from "@chakra-ui/react";
import constants from "../../styles/constants";
import _ from "lodash";

function UploadingFile({file}) {
    console.log(file)

    let title = file.name;

    if(file?.response?.song) {
        title = file.response.song.title + ' - ' + file.response.song.artist;
    }

    if(file?.response?.status) {
        title = _.capitalize(file.response.status) + ' - ' + title;
    }

  return (
    <Stack isInline alignItems="center">
      <Flex flex={1} overflow={"hidden"} alignItems={"center"}>
        <Flex mr={2} height={5} width={5} minWidth={5} alignItems={'center'} justifyContent={'center'}>
          {file.uploaded && <CheckCircleIcon name={"check-circle"} color={"green.500"}/>}
          {file.error && <WarningIcon color={"yellow.500"}/>}
          {!file.uploaded && !file.error && <Spinner
            color={constants.themeColor}
            height={4}
            width={4}
          />}
        </Flex>
        <Text isTruncated>{title}</Text>
      </Flex>
      {file.uploading && file.progress < 1 && (
        <Box width={"50%"}>
          <Progress value={file.progress * 100} width={'100%'}/>
        </Box>
      )}
    </Stack>
  )
}

export default UploadingFile;
