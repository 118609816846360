import React, {useState} from "react";
import {connect} from "react-redux";
import Head from "../../components/Head";
import SongsListing from "../SongsPage/SongsListing";
import MultipleCoverArt from "../../components/MultipleCoverArt";
import {useGenre} from "music-app-shared";
import {Box, Flex, Heading} from "@chakra-ui/react";

const mapStateToProps = state => {
  return {
    songs: state.songs.items,
    albums: state.songs.albums,
  };
};

function GenresShowPage({match, songs: librarySongs, albums: libraryAlbums, dispatch}) {
  const {name, songs, albums} = useGenre(match.params.name, librarySongs, libraryAlbums);

  const [sortBy, setSortBy] = useState([
    {
      id: 'title',
      desc: false
    }
  ]);

  return (
    <>
      <Head title={name}/>
      <SongsListing
        songs={songs}
        dispatch={dispatch}
        sortBy={sortBy}
        setSortBy={setSortBy}
        Header={(
          <Flex p={8}>
            <MultipleCoverArt width={230} height={230} songs={songs} borderRadius={"lg"} overflow={"hidden"}/>
            <Box ml={4}>
              <Heading mb={4}>{name}</Heading>
            </Box>
          </Flex>
        )}
      />
    </>
  )
}


export default connect(mapStateToProps)(GenresShowPage)
