import React, {useState} from "react";
import {connect} from "react-redux";
import Head from "../../components/Head";
import SongsListing from "./SongsListing";

const mapStateToProps = state => {
  return {
    songs: state.songs.items,
  };
};

function SongsPage({songs, dispatch}) {
  const [sortBy, setSortBy] = useState([
    {
      id: 'title',
      desc: false
    }
  ]);

  return (
    <>
      <Head title={"Songs"}/>
      <SongsListing songs={songs}
                    dispatch={dispatch}
                    sortBy={sortBy}
                    setSortBy={setSortBy}
      />
    </>
  )
}

export default connect(mapStateToProps)(SongsPage)
