import React from 'react';
import {MdRepeat, MdRepeatOne} from "react-icons/md";
import PlayerIcon from "./PlayerIcon";
import constants from "../../styles/constants";

export default function RepeatControl({repeat, setRepeat}) {
  const Icon = repeat === 'one' ? MdRepeatOne : MdRepeat;
  const color = repeat !== 'none' ? constants.themeColor : undefined;

  const onClick = (evt) => {
    evt.preventDefault();

    switch (repeat) {
      case 'none':
        setRepeat('all');
        break;
      case 'all':
        setRepeat('one');
        break;
      case 'one':
        setRepeat('none');
        break;
    }
  };

  return (
    <PlayerIcon
      icon={Icon}
      mr={2}
      color={color}
      onClick={onClick}
    />
  )
}
