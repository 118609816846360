import {Link as RouterLink} from "react-router-dom";
import React, {useMemo} from "react";
import Card from "../../components/Card";
import {connect} from "react-redux";
import {Link} from "@chakra-ui/react";

const mapStateToProps = (state) => ({
  songs: state.songs.items,
});

function GenreItem({genre, songs, ...props}) {
  const to = `/genres/${encodeURIComponent(genre)}`;

  const genreSongs = useMemo(() => {
    let foundSongs = [];

    for (let song of songs) {
      if (song.genre) {
        const songGenres = song.genre.split(',').map(genre => genre.trim());

        if (songGenres.indexOf(genre) !== -1) {
          foundSongs.push(song)
        }
      }

      if (foundSongs.length > 3) {
        break;
      }
    }

    return foundSongs;
  }, [songs, genre]);

  return (
    <Card to={to} songs={genreSongs} {...props}>
      <Link
        isTruncated
        as={RouterLink}
        to={to}
        fontWeight={"semibold"}
      >
        {genre}
      </Link>
    </Card>
  )
}

export default connect(mapStateToProps)(GenreItem);
