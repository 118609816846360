import React from 'react';
import {Redirect, Route} from 'react-router-dom';
import {connect} from 'react-redux';

const mapStateToProps = (state) => {
  return {
    profile: state.user.profile,
    token: state.user.token,
  }
};

const PrivateRoute = ({profile, token, children, ...props}) => {
  const authenticated = profile && token;

  return (
    <Route
      {...props}
      render={() => {
        if (!authenticated)
          return <Redirect to='/login'/>;
        return children;
      }}
    />
  )
};

export default connect(mapStateToProps)(PrivateRoute);
