export default (state = {
  search: '',
  profile: null,
  selectedSongs: [],
  deletingSongs: [],
  editingSongs: [],
  editingSoundcloudSongId: null,
  editingPlayCountId: null,
  editingPlaylistId: null,
}, action) => {
  switch (action.type) {
    case 'SET_SEARCH':
      return {
        ...state,
        search: action.payload,
      };
    case 'SET_SELECTED_SONGS':
      return {
        ...state,
        selectedSongs: action.payload,
      };
    case 'SET_DELETING_SONGS':
      return {
        ...state,
        deletingSongs: action.payload,
      };
    case 'SET_EDITING_SOUNDCLOUD_URL':
      return {
        ...state,
        editingSoundcloudSongId: action.payload,
      };
    case 'SET_EDITING_SONGS':
      return {
        ...state,
        editingSongs: action.payload,
      };
    case 'SET_EDITING_PLAY_COUNT_ID':
      return {
        ...state,
        editingPlayCountId: action.payload,
      };
    case 'SET_EDITING_PLAYLIST_ID':
      return {
        ...state,
        editingPlaylistId: action.payload,
      };
    default:
      return state;
  }
}
