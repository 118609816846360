import {useEffect, useState} from 'react';

export default function useLocalOrSessionStorageState(initialValue, storageKey, storageEngine = localStorage) {
  let newInitialValue = initialValue;

  if (storageEngine.getItem(storageKey) !== null) {
    try {
      newInitialValue = JSON.parse(storageEngine.getItem(storageKey));
    } catch (error) {
    }
  }

  const [state, setState] = useState(newInitialValue);

  useEffect(() => {
    storageEngine.setItem(storageKey, JSON.stringify(state));
  }, [state]);

  return [state, setState];
}
