import React, {useState} from 'react';
import LogoSvg from "./LogoSvg";
import {AspectRatio, Box, Flex, Image, Skeleton} from "@chakra-ui/react";

function CoverArt({songId, albumId, ...props}) {
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState(false);

  const src = songId ? `/api/songs/cover-art/${songId}` : `/api/songs/album-cover-art/${albumId}`;

  return (
    <AspectRatio ratio={1} {...props}>
      <Box backgroundColor={loaded ? 'gray.500' : 'gray.300'}>
        {!error && (
          <Image
            borderWidth={0}
            src={src}
            width={loaded ? '100%' : 0}
            height={loaded ? '100%' : 0}
            onLoad={() => setLoaded(true)}
            onError={() => setError(true)}
            objectFit={"cover"}
          />
        )}
        {!error && (
          <Skeleton
            width={!loaded ? '100%' : 0}
            height={!loaded ? '100%' : 0}
          />
        )}
        {error && <Flex position={'absolute'} left={0} right={0} top={0} bottom={0} alignItems={"center"} justifyContent={"center"}>
          <LogoSvg
            fill={"#7a7a7a"}
            height={'25%'}
            width={'25%'}
          />
        </Flex>}
      </Box>
    </AspectRatio>
  )

}

export default CoverArt;
