import {Formik} from "formik";
import React, {useMemo} from "react";
import TextInput from "../TextInput";
import {connect} from "react-redux";
import _ from 'lodash';
import {gql, useMutation} from "@apollo/client";
import {Box, AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Button, Spinner} from "@chakra-ui/react";

const EDIT_SONGS = gql`
  mutation EditSongs($ids: [Int!]!, $title: String, $album: String, $artist: String, $genre: String) {
    editSongs(ids: $ids, title: $title, album: $album, genre: $genre, artist: $artist) {
      id
      title
      artist
      artist_sort
      album_id
      album
      length
      genre
    }
  }
`;

const mapStateToProps = (state) => ({
  songs: state.songs.items,
  editingSongs: state.app.editingSongs
});

function EditSongsDialog({editingSongs, dispatch, songs}) {
  const filteredSongs = useMemo(() => {
    return songs.filter(song => editingSongs.indexOf(song.id) !== -1)
  }, [songs, editingSongs]);

  const [editSongs] = useMutation(EDIT_SONGS);

  const cancelRef = React.useRef();

  const clickCancel = () => {
    dispatch({
      type: 'SET_EDITING_SONGS',
      payload: [],
    })
  };

  const isEditing = editingSongs.length > 0;

  const canEditName = editingSongs.length === 1;
  const canEditArtist = _.uniq(filteredSongs.map(song => song.artist)).length <= 1;
  const canEditAlbum = _.uniq(filteredSongs.map(song => song.album)).length <= 1;
  const canEditGenre = _.uniq(filteredSongs.map(song => song.genre)).length <= 1;

  const onSubmit = (values, form) => {
    const data = {
      ids: filteredSongs.map(song => song.id),
    };

    if (canEditName) {
      data.title = values.title;
    }

    if (canEditArtist) {
      data.artist = values.artist;
    }

    if (canEditAlbum) {
      data.album = values.album;
    }

    if (canEditGenre) {
      data.genre = values.genre;
    }

    return editSongs({
      variables: data
    })
      .then(response => {
        dispatch({
          type: 'PATCH_MULTIPLE_SONGS',
          payload: response.data.editSongs,
        })

        clickCancel();
      })
  };

  return (
    <AlertDialog
      isOpen={isEditing}
      leastDestructiveRef={cancelRef}
      onClose={() => null}
    >
      <AlertDialogOverlay/>
      <AlertDialogContent>
        <AlertDialogHeader fontSize="lg" fontWeight="bold">
          Edit {editingSongs.length > 1 ? `${editingSongs.length} songs` : 'song'} info
        </AlertDialogHeader>
        <Formik
          onSubmit={onSubmit}
          enableReinitialize={true}
          initialValues={{
            title: canEditName ? filteredSongs[0].title : '...',
            artist: isEditing && canEditArtist ? filteredSongs[0].artist : '...',
            album: isEditing && canEditAlbum ? filteredSongs[0].album : '...',
            genre: isEditing && canEditGenre ? filteredSongs[0].genre : '...',
          }}
        >
          {form => {
            return (
              <>
                <AlertDialogBody>
                  <Box mb={2}>
                    <TextInput name="title" label="Title" disabled={!canEditName}/>
                  </Box>
                  <Box mb={2}>
                    <TextInput name="artist" label="Artist" disabled={!canEditArtist}/>
                  </Box>
                  <Box mb={2}>
                    <TextInput name="album" label="Album" disabled={!canEditAlbum}/>
                  </Box>
                  <Box mb={2}>
                    <TextInput name="genre" label="Genre" disabled={!canEditGenre}/>
                  </Box>
                </AlertDialogBody>
                <AlertDialogFooter>
                  <Button ref={cancelRef} onClick={clickCancel}>
                    Cancel
                  </Button>
                  <Button colorScheme="green" onClick={form.submitForm} ml={3}>
                    {form.isSubmitting ? 'Saving' : 'Save'} {form.isSubmitting && <Spinner size="sm" ml={2}/>}
                  </Button>
                </AlertDialogFooter>
              </>
            )
          }}
        </Formik>
      </AlertDialogContent>
    </AlertDialog>
  )
}

export default connect(mapStateToProps)(EditSongsDialog);
