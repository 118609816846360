import React from "react";
import PlaylistItem from "./PlaylistItem";
import AutoSizer from "react-virtualized-auto-sizer";
import CardListing from "../../components/layout/CardListing";

export default function PlaylistListing({playlists}) {
  return (
    <AutoSizer>
      {({width, height}) => (
        <CardListing
          width={width}
          height={height}
          items={playlists}
          cardRenderer={item => <PlaylistItem playlist={item}/>}
        />
      )}
    </AutoSizer>
  )
}
