import React, {useState} from "react";
import {connect} from "react-redux";
import Head from "../../components/Head";
import SongsListing from "../SongsPage/SongsListing";
import {useArtist} from "music-app-shared";
import {Avatar, Box, Flex, Heading} from "@chakra-ui/react";

const mapStateToProps = state => {
  return {
    albums: state.songs.albums,
    songs: state.songs.items,
  };
};

function ArtistsShowPage({match, albums: libraryAlbums, songs: librarySongs, dispatch}) {
  const {name, albums, songs} = useArtist(match.params.name, librarySongs, libraryAlbums);

  const [sortBy, setSortBy] = useState([
    {
      id: 'title',
      desc: false
    }
  ]);

  return (
    <>
      <Head title={name}/>
      <SongsListing
        songs={songs}
        dispatch={dispatch}
        sortBy={sortBy}
        setSortBy={setSortBy}
        Header={(
          <Flex p={8}>
            <Avatar
              name={name}
              size={"2xl"}
            />
            <Box ml={4}>
              <Heading mb={4}>{name}</Heading>
            </Box>
          </Flex>
        )}
      />
    </>
  )
}


export default connect(mapStateToProps)(ArtistsShowPage)
