import React, {useMemo, useState} from "react";
import {connect} from "react-redux";
import apiAxios from "../../service/apiAxios";
import Promise from 'bluebird';
import {AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Button, List, ListItem, Spinner} from "@chakra-ui/react";

const mapStateToProps = (state) => ({
  deletingSongs: state.app.deletingSongs,
  songs: state.songs.items,
});

function DeleteSongsDialog({deletingSongs, dispatch, songs}) {
  const cancelRef = React.useRef();
  const [loading, setLoading] = useState(false);

  const filteredSongs = useMemo(() => {
    return songs.filter(song => deletingSongs.indexOf(song.id) !== -1)
  }, [deletingSongs]);

  const clickCancel = () => {
    dispatch({
      type: 'SET_DELETING_SONGS',
      payload: [],
    })
  };

  const clickDelete = () => {
    setLoading(true);
    Promise.map(
      deletingSongs,
      (songId) => {
        return apiAxios({
          url: `/api/songs/${songId}`,
          method: 'DELETE'
        })
          .then(response => {
            dispatch({
              type: 'DELETE_SONG',
              payload: {
                id: songId,
              },
            })
          })
      },
      {concurrency: 1}
    )
      .then(() => clickCancel())
      .finally(() => setLoading(false))
  };

  return (
    <AlertDialog
      isOpen={deletingSongs.length > 0}
      leastDestructiveRef={cancelRef}
      onClose={() => null}
    >
      <AlertDialogOverlay/>
      <AlertDialogContent>
        <AlertDialogHeader fontSize="lg" fontWeight="bold">
          {deletingSongs.length === 1 ? 'Delete song' : `Delete ${deletingSongs.length} songs`}
        </AlertDialogHeader>
        <AlertDialogBody>
          Are you sure? You can't undo this action afterwards.
          <List styleType="disc" pl={2} mt={4}>
            {filteredSongs.map(song => {
              return (
                <ListItem key={song.id}>{song.title} by {song.artist}</ListItem>
              )
            })}
          </List>
        </AlertDialogBody>
        <AlertDialogFooter>
          <Button ref={cancelRef} onClick={clickCancel}>
            Cancel
          </Button>
          <Button colorScheme="red" onClick={clickDelete} ml={3}>
            {loading ? 'Deleting' : 'Delete'}{loading && <Spinner size="sm" ml={2}/>}
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}

export default connect(mapStateToProps)(DeleteSongsDialog);
