import {Formik} from "formik";
import React, {useMemo} from "react";
import TextInput from "../TextInput";
import {connect} from "react-redux";
import _ from 'lodash';
import {gql, useMutation} from "@apollo/client";
import {Box, AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Button, Spinner} from "@chakra-ui/react";

const EDIT_SONGS = gql`
  mutation SetSongSoundcloudUrl($id: Int!, $soundcloud_url: String!) {
    setSongSoundcloudUrl(id: $id, soundcloud_url: $soundcloud_url) {
      id
      soundcloud_url
    }
  }
`;

const mapStateToProps = (state) => ({
  songs: state.songs.items,
  editingSoundcloudSongId: state.app.editingSoundcloudSongId
});

function EditSoundcloudUrlDialog({editingSoundcloudSongId, dispatch, songs}) {
  const matchedSong = useMemo(() => {
    return songs.find(song => song.id === editingSoundcloudSongId)
  }, [songs, editingSoundcloudSongId]);

  const [setSongSoundcloudUrl] = useMutation(EDIT_SONGS);

  const cancelRef = React.useRef();

  const clickCancel = () => {
    dispatch({
      type: 'SET_EDITING_SOUNDCLOUD_URL',
      payload: null,
    })
  };

  const onSubmit = (values, form) => {
    return setSongSoundcloudUrl({
      variables: values
    })
      .then(response => {
        dispatch({
          type: 'PATCH_MULTIPLE_SONGS',
          payload: [response.data.setSongSoundcloudUrl],
        })

        clickCancel();
      })
  };

  return (
    <AlertDialog
      isOpen={!!editingSoundcloudSongId}
      leastDestructiveRef={cancelRef}
      onClose={() => null}
    >
      <AlertDialogOverlay/>
      <AlertDialogContent>
        <AlertDialogHeader fontSize="lg" fontWeight="bold">
          Editing {matchedSong?.title}
        </AlertDialogHeader>
        <Formik
          onSubmit={onSubmit}
          enableReinitialize={true}
          initialValues={{
            id: matchedSong?.id,
            soundcloud_url: matchedSong?.soundcloud_url,
          }}
        >
          {form => {
            return (
              <>
                <AlertDialogBody>
                  <Box mb={2}>
                    <TextInput name="soundcloud_url" label="Soundcloud URL"/>
                  </Box>
                </AlertDialogBody>
                <AlertDialogFooter>
                  <Button ref={cancelRef} onClick={clickCancel}>
                    Cancel
                  </Button>
                  <Button colorScheme="green" onClick={form.submitForm} ml={3}>
                    {form.isSubmitting ? 'Saving' : 'Save'} {form.isSubmitting && <Spinner size="sm" ml={2}/>}
                  </Button>
                </AlertDialogFooter>
              </>
            )
          }}
        </Formik>
      </AlertDialogContent>
    </AlertDialog>
  )
}

export default connect(mapStateToProps)(EditSoundcloudUrlDialog);
