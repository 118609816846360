import React from "react";
import TextInput from "../../components/TextInput";
import {Formik} from "formik";
import PasswordInput from "../../components/PasswordInput";
import {connect} from "react-redux";
import axios from 'axios';
import Helmet from "react-helmet";
import apiAxios from "../../service/apiAxios";
import {Box, Button, Flex, Spinner} from "@chakra-ui/react";

function LoginPage({dispatch}) {
  const onSubmit = (values, form) => {
    return axios({
      url: '/api/auth/login',
      method: 'POST',
      data: values
    })
      .then(async response => {
        dispatch({
          type: 'SET_TOKEN',
          payload: response.data.accessToken
        })

        return apiAxios({url: '/api/profile'})
          .then(response => {
            dispatch({
              type: 'SET_PROFILE',
              payload: response.data
            })

            window.location.href = '/'
          })
      })
      .catch(error => {
        if (error.response && error.response.data) {
          form.setErrors(error.response.data.errors)
        } else {
          throw error;
        }
      })
  };

  return (
    <Flex flexDirection="column">
      <Helmet>
        <title>Login</title>
      </Helmet>
      <Box
        p="6"
        as="main"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Box borderWidth={1} borderRadius="lg" p={6}>
          <Formik
            initialValues={{
              username: '',
              password: '',
            }}
            onSubmit={onSubmit}
          >
            {form => {
              return (
                <>
                  <Box mb={4}>
                    <TextInput name="username" label="Username"/>
                  </Box>
                  <Box mb={4}>
                    <PasswordInput name="password" label="Password"/>
                  </Box>
                  <Button colorScheme="teal" onClick={form.submitForm}>{form.isSubmitting ? 'Loading' : 'Login'}{form.isSubmitting && <Spinner size="sm" ml={2}/>}</Button>
                </>
              )
            }}
          </Formik>
        </Box>
      </Box>
    </Flex>
  )
};

export default connect()(LoginPage)
