import React, {useState} from "react";
import {connect} from "react-redux";
import Head from "../../components/Head";
import SongsListing from "../SongsPage/SongsListing";
import {Link as RouterLink} from "react-router-dom";
import CoverArt from "../../components/CoverArt";
import {useAlbum} from "music-app-shared";
import {Box, Flex, Heading, Link, Text} from "@chakra-ui/react";

const mapStateToProps = state => {
  return {
    albums: state.songs.albums,
    songs: state.songs.items,
  };
};

function AlbumsShowPage({match, albums, songs: librarySongs, dispatch}) {
  const {album, songs} = useAlbum(match.params.id, albums, librarySongs);

  const [sortBy, setSortBy] = useState([
    {
      id: 'title',
      desc: false
    }
  ]);

  return (
    <>
      <Head title={album.album}/>
      <SongsListing
        songs={songs}
        dispatch={dispatch}
        sortBy={sortBy}
        setSortBy={setSortBy}
        Header={(
          <Flex p={8}>
            <CoverArt width={230} albumId={album.id} borderRadius="lg" overflow={"hidden"}/>
            <Box ml={4}>
              <Heading mb={4}>{album.album}</Heading>
              <Link
                display={"block"}
                color={"gray.500"}
                as={RouterLink}
                to={`/artists/${encodeURIComponent(album.albumartist)}`}
              >{album.albumartist}</Link>
              <Text color={"gray.500"}>{album.year} • {songs.length} songs</Text>
            </Box>
          </Flex>
        )}
      />
    </>
  )
}


export default connect(mapStateToProps)(AlbumsShowPage)
