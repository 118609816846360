import React from "react";
import {connect} from "react-redux";
import Head from "../../components/Head";
import GenreListing from "./GenreListing";
import {useGenres} from "music-app-shared";

const mapStateToProps = state => {
  return {
    songs: state.songs.items,
    albums: state.songs.albums,
  };
}

function GenresPage({songs, albums}) {
  const genres = useGenres(songs, albums);

  return (
    <>
      <Head/>
      <GenreListing genres={genres}/>
    </>
  )
}


export default connect(mapStateToProps)(GenresPage)
