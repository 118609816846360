import React from 'react';
import {MdVolumeDown, MdVolumeMute, MdVolumeOff, MdVolumeUp} from "react-icons/md";
import PlayerIconWithVerticalSlider from "./PlayerIconWithVerticalSlider";

export default function VolumeControl({volume, setVolume, muted, setMuted}) {
  let Icon = null;

  if (muted) {
    Icon = MdVolumeOff
  } else if (volume <= 5) {
    Icon = MdVolumeMute;
  } else if (volume <= 50) {
    Icon = MdVolumeDown;
  } else {
    Icon = MdVolumeUp;
  }

  const onClick = () => setMuted(!muted);

  return (
    <PlayerIconWithVerticalSlider
      PlayerIconProps={{
        icon: Icon,
        onClick,
      }}
      SliderProps={{
        min: 0,
        max: 100,
        value: volume,
        onChange: setVolume,
      }}
    />
  );
}
