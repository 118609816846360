import React, {useCallback, useEffect, useRef, useState} from "react";
import {useSortBy, useTable} from "react-table";
import {MdMusicNote, MdTimelapse} from "react-icons/md";
import AutoSizer from 'react-virtualized-auto-sizer';
import {areEqual, VariableSizeList} from 'react-window';
import {Link as RouterLink} from "react-router-dom";
import moment from 'moment';
import {FaHeart} from "react-icons/fa";
import RatingColumn from "./SongRow/RatingColumn";
import PlayCount from "./SongRow/PlayCount";
import {Box, Flex, Link, Text} from "@chakra-ui/react";
import SongRow from "./SongRow";
import {ArrowDownIcon, ArrowUpIcon} from "@chakra-ui/icons";
import TitleColumn from "./SongRow/TitleColumn";
import useSessionStorageState from "../../components/useSessionStorageState";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import useDebugMount from "../../hooks/useDebugMount";
import ReactDOM from "react-dom";

function convertRemToPixels(rem) {
  return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
}

function HeaderPortal({Header, headerRef}) {
  if (headerRef.current) {
    return ReactDOM.createPortal(Header, headerRef.current)
  } else {
    return null;
  }
}

function SongsListing({songs, Header, defaultSortBy, sortBy, setSortBy, onDragEnd, showAdded = false}) {
  useDebugMount('SongsListing');
  const [rowIndex, setRowIndex] = useSessionStorageState(0, 'song-listing.scroll-position.' + window.location.href);

  const columns = React.useMemo(
    () => {
      return [
        {
          Header: 'Name',
          accessor: 'title',
          flex: 1,
          Cell: ({cell, value}) => {
            return (
              <TitleColumn cell={cell}/>
            )
          }
        },
        {
          Header: () => {
            return <Box as={MdTimelapse} size="22px" color="gray.700"/>
          },
          width: '80px',
          accessor: 'length',
          Cell: ({cell, value}) => {
            const seconds = Math.round(parseFloat(cell.value));

            const minutes = Math.floor(seconds / 60);

            let secondsLeft = (seconds - (minutes * 60)).toString();

            if (secondsLeft.length === 1) {
              secondsLeft = "0" + secondsLeft;
            }

            return <Text
              isTruncated
              fontSize={'sm'}
            >
              {`${minutes}:${secondsLeft}`}
            </Text>;
          }
        },
        {
          Header: 'Artist',
          accessor: 'artist',
          Cell: ({cell, value}) => {
            return (
              <Link
                as={RouterLink}
                to={`/artists/${encodeURIComponent(cell.value)}`}
                color={"gray.500"}
                letterSpacing="wide"
                isTruncated
                fontSize={'sm'}
              >
                {cell.value}
              </Link>
            );
          },
          width: '250px',
        },
        {
          Header: 'Album',
          accessor: 'album',
          Cell: ({cell, value}) => {
            return (
              <Link
                as={RouterLink}
                to={`/albums/${cell.row.original.album_id || encodeURIComponent(cell.row.original.album)}`}
                color={"gray.500"}
                letterSpacing="wide"
                isTruncated
                fontSize={'sm'}
              >
                {cell.value}
              </Link>
            );
          },
          width: '250px',
        },
        showAdded && {
          Header: 'Added',
          accessor: 'added',
          Cell: ({cell, value}) => {
            const date = moment(cell.value * 1000);

            return (
              <Text
                isTruncated
                fontSize={'sm'}
              >{date.format('DD/MM/YY')}</Text>
            );
          },
          width: '100px',
        },
        {
          Header: () => {
            return <Box as={MdMusicNote} size="22px" color="gray.700"/>
          },
          accessor: 'play_count',
          Cell: ({cell, value}) => {
            return <PlayCount cell={cell}/>
          },
          width: '80px',
        },
        {
          Header: () => {
            return <Box as={FaHeart} size="22px" color="gray.700"/>
          },
          accessor: 'liked_at',
          Cell: ({cell, value}) => {
            return <RatingColumn cell={cell} value={value}/>;
          },
          width: '60px',
        },
      ].filter(row => !!row)
    },
    []
  );

  const headerRef = useRef();
  const [listRef, setListRef] = useState();
  const restoredPosition = useRef(false);

  useEffect(() => {
    if (listRef) {
      console.log({rowIndex})
      restoredPosition.current = true;
      setTimeout(() => listRef.scrollToItem(rowIndex, 'start'))
    }
  }, [listRef]);

  const {
    headerGroups,
    rows,
    prepareRow,
    state
  } = useTable({
    columns, data: songs,
    initialState: {
      sortBy: sortBy || defaultSortBy || [
        {
          id: 'title',
          desc: false
        }
      ]
    }
  }, useSortBy);

  const itemCount = 3 + rows.length;

  useEffect(() => {
    if (setSortBy) {
      setSortBy(state.sortBy)
    }
  }, [state.sortBy]);

  let intervalId = setInterval(() => {
    if (headerRef && headerRef.current && headerRef.current.offsetHeight > 0) {
      listRef && listRef.resetAfterIndex(0)
      clearInterval(intervalId)
    }
  }, 0);

  setTimeout(() => {
    clearInterval(intervalId);
  }, 1000)

  const Row = React.useCallback(
    React.memo(({data, index, style}) => {
      if (index === 0) {
        return (
          <Box ref={headerRef}/>
        );
      } else if (index === 1) {
        const headerGroup = headerGroups[0];

        return (
          <Box style={style} {...headerGroup.getHeaderGroupProps()}>
            <Box p={6} pb={0}>
              <Flex height={"40px"}>
                {headerGroup.headers.map(column => {
                  return (
                    <Flex px={4} {...column.getHeaderProps(column.getSortByToggleProps())} width={column.width} flex={column.flex}>
                      <Text position={'relative'}>{column.render('Header')} <Box position={'absolute'} top={'-2px'} left={'110%'}>{column.isSorted ? column.isSortedDesc ? <ArrowDownIcon/> : <ArrowUpIcon/> : null}</Box></Text>
                    </Flex>
                  );
                })}
              </Flex>
            </Box>
          </Box>
        )
      } else if (index === data.length + 2) {
        return (
          <Box pb={6} key={'footer'}/>
        );
      } else {
        const dataRow = index - 2;

        const row = data[dataRow];

        prepareRow(row);

        if (onDragEnd) {
          return (
            <Draggable draggableId={`row-${index}`} index={index} key={`row-${index}`}>
              {(provided, snapshot) => (
                <ListingSongRow
                  style={style}
                  row={row}
                  data={data}
                  provided={provided}
                  isDragging={snapshot.isDragging}
                />
              )}
            </Draggable>
          )
        } else {
          return (
            <ListingSongRow
              style={style}
              row={row}
              data={data}
            />
          )
        }
      }
    }, areEqual),
    [prepareRow]
  );

  const ListingSongRow = ({row, style, data, provided}) => {
    return (
      <Box
        {...row.getRowProps()}
        px={6}
        ref={provided?.innerRef}
        {...provided?.draggableProps}
        {...provided?.dragHandleProps}
        style={{
          ...style,
          ...provided?.draggableProps.style
        }}
      >
        <SongRow song={row.original} rows={data}>
          {row.cells.map((cell, index) => {
            return (
              <Box {...cell.getCellProps()} px={4} width={cell.column.width} overflow={"hidden"} flex={cell.column.flex}>
                <Flex align={'center'}>
                  {cell.render('Cell')}
                </Flex>
              </Box>
            )
          })}
        </SongRow>
      </Box>
    )
  }

  const itemSize = index => {
    if (index === 0) {
      return (headerRef && headerRef.current && headerRef.current.offsetHeight) || (Header ? 294 : 0);
    } else if (index === 1 || index === itemCount - 1) {
      return 40 + convertRemToPixels(1.5)
    } else if (index === songs.length + 1) {
      return convertRemToPixels(1.5)
    } else {
      return 40;
    }
  };

  const onItemsRendered = ({visibleStartIndex}) => {
    if (restoredPosition.current === true) {
      setRowIndex(visibleStartIndex)
    }
  }

  const renderClone = (
    provided,
    snapshot,
    rubric,
  ) => {
    let index = rubric.source.index

    const dataRow = index - 2;

    const row = rows[dataRow];

    prepareRow(row);

    return (
      <ListingSongRow
        provided={provided}
        row={row}
        data={rows}
      />
    )
  };

  const DroppableRow = useCallback(
    (droppableProvided) => (
      <AutoSizer>
        {({width, height}) => {
          return (
            <VariableSizeList
              overscanCount={30}
              height={height}
              width={width}
              itemSize={itemSize}
              itemData={rows}
              estimatedItemSize={40}
              itemCount={itemCount}
              ref={setListRef}
              onItemsRendered={onItemsRendered}
              outerRef={droppableProvided.innerRef}
            >
              {Row}
            </VariableSizeList>
          )
        }}
      </AutoSizer>
    ),
    [rows]
  );

  console.log({DroppableRow});

  if (onDragEnd) {
    return (
      <>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable
            droppableId="droppable"
            mode="virtual"
            renderClone={renderClone}
          >
            {DroppableRow}
          </Droppable>
        </DragDropContext>
        <HeaderPortal
          headerRef={headerRef}
          Header={Header}
        />
      </>
    )
  } else {
    return (
      <>
        <AutoSizer>
          {({width, height}) => {
            return (
              <VariableSizeList
                overscanCount={30}
                height={height}
                width={width}
                itemSize={itemSize}
                itemData={rows}
                estimatedItemSize={40}
                itemCount={itemCount}
                ref={setListRef}
                onItemsRendered={onItemsRendered}
              >
                {Row}
              </VariableSizeList>
            )
          }}
        </AutoSizer>
        <HeaderPortal
          headerRef={headerRef}
          Header={Header}
        />
      </>
    )
  }
}

export default SongsListing;
