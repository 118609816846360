import React, {useEffect} from 'react';
import {Flex, Spinner, Text} from "@chakra-ui/react"
import {connect} from "react-redux";
import {gql, useQuery} from "@apollo/client";
import Player from "../../components/layout/Player";
import constants from "../../styles/constants";

const SONGS_QUERY = gql`
  query {
    songs {
      id
      title
      artist
      artist_sort
      album_id
      album
      length
      genre
      added
      play_count
      play_time
      liked_at
    }
    albums {
      id
      albumartist
      album
      genre
      year
      label
    }
    playlists {
      id
      name
      song_ids
    }
  }
`;

const mapStateToProps = state => ({
  playlists: state.songs.playlists,
  songs: state.songs.items,
  albums: state.songs.albums,
});

function AppContainer({children, dispatch, playlists, songs, albums}) {
  const {loading, error, data} = useQuery(SONGS_QUERY);

  useEffect(() => {
    if (data) {
      if (data.songs) {
        dispatch({
          type: 'SET_SONGS',
          payload: data.songs,
        })
      }

      if (data.albums) {
        dispatch({
          type: 'SET_ALBUMS',
          payload: data.albums,
        })
      }

      if (data.playlists) {
        dispatch({
          type: 'SET_PLAYLISTS',
          payload: data.playlists,
        })
      }
    }
  }, [dispatch, data]);

  if (error) {
    return (
      <Flex flex={1} alignItems="center" justifyContent="center" minHeight="100vh" flexDirection="column">
        <Text>Failed to load library</Text>
      </Flex>
    )
  } else if (playlists && songs && albums) {
    return (
      <Flex flex={1} flexDirection={"column"} height="100vh">
        <Flex flex={1} flexDirection={"column"} overflowY={"auto"}>
          {children}
        </Flex>
        <Player/>
      </Flex>
    )
  } else {
    return (
      <Flex flex={1} alignItems="center" justifyContent="center" minHeight="100vh" flexDirection="column">
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color={constants.themeColor}
          size="xl"
          mb={4}
        />
        <Text align={'center'}>Loading</Text>
      </Flex>
    )
  }
}

export default connect(mapStateToProps)(AppContainer)
