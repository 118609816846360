import {useEffect} from "react";

export default function useDebugMount(name) {
  useEffect(() => {
    console.log(`*** MOUNTED: ${name} ***`)

    return () => console.log(`*** UNMOUNTED: ${name} ***`)
  }, [])

  useEffect(() => {
    console.log(`*** RENDER: ${name} ***`);
  })
}
