import _ from 'lodash';
import axios from 'axios';

let store = null;

export function setApiStore(newStore) {
  store = newStore;
}

function apiAxios(config) {
  config = _.defaults(config, {});

  if (store) {
    const state = store.getState();

    if (state.user.token) {
      _.set(config, 'headers.Authorization', `Bearer ${state.user.token}`);
    }
  }

  return axios(config);
}

export default apiAxios
