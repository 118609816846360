import React from "react";
import AlbumItem from "./AlbumItem";
import AutoSizer from "react-virtualized-auto-sizer";
import CardListing from "../../components/layout/CardListing";

export default function AlbumListing({albums}) {
  return (
    <AutoSizer>
      {({width, height}) => (
        <CardListing
          width={width}
          height={height}
          items={albums}
          cardRenderer={item => <AlbumItem album={item}/>}
        />
      )}
    </AutoSizer>
  )
}
