import React from "react";
import {connect} from "react-redux";
import Head from "../../components/Head";
import AlbumListing from "./AlbumListing";
import {useAlbums} from "music-app-shared";

const mapStateToProps = state => {
  return {
    albums: state.songs.albums,
  };
};

function AlbumsPage({albums}) {
  const sortedAlbums = useAlbums(albums);

  return (
    <>
      <Head/>
      <AlbumListing albums={sortedAlbums}/>
    </>
  )
}


export default connect(mapStateToProps)(AlbumsPage)
