import React, {useState} from 'react';
import {connect} from "react-redux";
import {Text} from "@chakra-ui/react";

function PlayCount({cell, dispatch}) {
  const [lastPressedCount, setLastPressedCount] = useState(0);

  const onClick = (event) => {
    event.preventDefault();

    const currentTimestamp = (new Date()).getTime();

    if (lastPressedCount > currentTimestamp - 500) {
      dispatch({
        type: 'SET_EDITING_PLAY_COUNT_ID',
        payload: parseInt(cell.row.original.id),
      })
    }

    setLastPressedCount(currentTimestamp);
  };

  return (
    <>
      <Text
        isTruncated
        onClick={onClick}
        fontSize={'sm'}
      >{cell.value || 0}</Text>
    </>
  );
}

const Styles = {}

export default connect()(PlayCount);
