import React, {useEffect, useState} from 'react';
import {Flex, Spinner, Text} from "@chakra-ui/react"
import {connect} from "react-redux";
import Player from "./Player";
import Header from "./Header";
import {gql, useApolloClient} from "@apollo/client";
import DeleteSongsDialog from "./DeleteSongsDialog";
import EditSongsDialog from "./EditSongsDialog";
import ChangePlayCountDialog from "../ChangePlayCountDialog";
import EditSoundcloudUrlDialog from "./EditSoundcloudUrlDialog";
import constants from "../../styles/constants";

const SONGS_QUERY = gql`
  query {
    songs {
      id
      title
      artist
      artist_sort
      album_id
      album
      length
      genre
      added
      play_count
      play_time
      liked_at
      soundcloud_url
      source_url
    }
    albums {
      id
      albumartist
      album
      genre
      year
      label
    }
    playlists {
      id
      name
      song_ids
    }
  }
`;

const mapStateToProps = state => ({
  playlists: state.songs.playlists,
  songs: state.songs.items,
  albums: state.songs.albums,
  profile: state.user.profile,
  token: state.user.token,
});

export const refreshPlayer = React.createRef();

function AppContainer({children, dispatch, playlists, songs, albums, token, profile}) {
  const client = useApolloClient();
  const [error, setError] = useState();
  const [loading, setLoading] = useState(true);

  const refetch = () => {
    client.query({
      query: SONGS_QUERY,
        fetchPolicy: 'no-cache'
    })
      .then(response => {
        dispatch({
          type: 'SET_SONGS',
          payload: response.data.songs,
        })
        dispatch({
          type: 'SET_ALBUMS',
          payload: response.data.albums,
        })
        dispatch({
          type: 'SET_PLAYLISTS',
          payload: response.data.playlists,
        })
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false))
  }

  refreshPlayer.current = refetch;

  useEffect(() => refetch(), [])

  window.refreshPlayer = refetch;

  if ((!token || !profile)) {
    if (window.location.pathname !== '/login') {
      window.location.href = '/login';
    }
    return null;
  }

  if (error) {
    return (
      <Flex flex={1} alignItems="center" justifyContent="center" minHeight="100vh" flexDirection="column">
        <Text>Failed to load library</Text>
      </Flex>
    )
  } else if (playlists && songs && albums) {
    return (
      <Flex flex={1} flexDirection={"column"} height="100vh">
        <Header/>
        <Flex flex={1} flexDirection={"column"} overflowY={"auto"}>
          {children}
        </Flex>
        <Player/>
        <DeleteSongsDialog/>
        <EditSongsDialog/>
        <EditSoundcloudUrlDialog/>
        <ChangePlayCountDialog/>
      </Flex>
    )
  } else {
    return (
      <Flex flex={1} alignItems="center" justifyContent="center" minHeight="100vh" flexDirection="column">
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color={constants.themeColor}
          size="xl"
          mb={4}
        />
        <Text align={'center'}>Loading music library</Text>
      </Flex>
    )
  }
}

export default connect(mapStateToProps)(AppContainer)
