import {Grid} from "react-virtualized";
import React from "react";
import ArtistItem, {ratio} from "./ArtistItem";
import {Box} from "@chakra-ui/react"

export default function ArtistListing({width, height, artists}) {
  const columnCount = 6;

  const columnWidth = (width - 16) / columnCount;

  const cellRenderer = ({columnIndex, key, rowIndex, style}) => {
    const index = (rowIndex * columnCount) + columnIndex
    const artist = artists[index]
    return (
      <Box key={key} style={style} p={4}>
        <ArtistItem artist={artist}/>
      </Box>
    )
  };

  const noContentRenderer = () => {

  };

  return (
    <Grid
      cellRenderer={cellRenderer}
      columnWidth={columnWidth}
      noContentRenderer={noContentRenderer}
      height={height}
      width={width}
      rowHeight={columnWidth / ratio}
      rowCount={Math.ceil(artists.length / columnCount)}
      columnCount={columnCount}
    />
  )
}
