import React, {useEffect, useMemo, useRef, useState} from 'react';
import {AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Button, Input, Spinner} from "@chakra-ui/react";
import {gql, useMutation} from "@apollo/client";
import {connect} from "react-redux";

const SET_SONG_PLAY_COUNT = gql`
  mutation SetSongPlayCount($id: Int!, $play_count: Int!) {
    setSongPlayCount(id: $id, play_count: $play_count) {
      id
      play_count
    }
  }
`;

const mapStateToProps = (state) => ({
  songs: state.songs.items,
  editingPlayCountId: state.app.editingPlayCountId
});

function ChangePlayCountDialog({dispatch, songs, editingPlayCountId}) {
  const song = useMemo(() => {
    return songs.find(song => song.id === editingPlayCountId)
  }, [songs, editingPlayCountId]);

  const [setSongPlayCount] = useMutation(SET_SONG_PLAY_COUNT);
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState(0);

  useEffect(() => {
    if (song) {
      setValue(song.play_count);
    }
  }, [song]);

  const cancelRef = useRef();

  const clickCancel = () => {
    dispatch({
      type: 'SET_EDITING_PLAY_COUNT_ID',
      payload: null,
    })
  };

  const clickSave = () => {
    setLoading(true);
    setSongPlayCount({
      variables: {
        id: song.id,
        play_count: parseInt(value)
      }
    })
      .then(response => {
        dispatch({
          type: 'PATCH_MULTIPLE_SONGS',
          payload: [response.data.setSongPlayCount],
        })
        clickCancel();
      })
      .finally(() => setLoading(false))
  };

  const onChangePlayCount = (event) => {
    setValue(event.target.value);
  }

  return (
    <AlertDialog
      isOpen={!!song}
      leastDestructiveRef={cancelRef}
      onClose={() => null}
    >
      <AlertDialogOverlay/>
      <AlertDialogContent>
        <AlertDialogHeader fontSize="lg" fontWeight="bold">
          Edit Play Count
        </AlertDialogHeader>
        <AlertDialogBody>
          Are you sure? You can't undo this action afterwards.
          <Input type="number" placeholder="New play count..." value={value} onChange={onChangePlayCount}/>
        </AlertDialogBody>
        <AlertDialogFooter>
          <Button ref={cancelRef} onClick={clickCancel}>
            Cancel
          </Button>
          <Button colorScheme="red" onClick={clickSave} ml={3}>
            {loading ? 'Saving' : 'Save'}{loading && <Spinner size="sm" ml={2}/>}
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}

const Styles = {}

export default connect(mapStateToProps)(ChangePlayCountDialog);
