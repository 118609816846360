import React, {useEffect, useRef, useState} from "react";
import {connect} from "react-redux";
import Head from "../../components/Head";
import {useDropzone} from 'react-dropzone'
import {v4 as uuidv4} from 'uuid';
import apiAxios from "../../service/apiAxios";
import YoutubeDLDialog from "./YoutubeDLDialog";
import BeetsConsole from "./BeetsConsole";
import {gql, useMutation, useQuery} from "@apollo/client";
import {Box, Button, CloseButton, Flex, Heading, HStack, List, ListItem, Text} from "@chakra-ui/react";
import {refreshPlayer} from '../../components/layout/AppContainer';
import UploadingFile from "./UploadingFile";

let inProgress = false;

const DOWNLOADED_FILES_QUERY = gql`
  query {
    downloadedFiles {
      path
      name
    }
  }
`;

const DELETE_DOWNLOADED_FILE = gql`
  mutation DeleteDownloadedSong($path: String!) {
    deleteDownloadedFile(path: $path) {
      path
      name
    }
  }
`;

const mapStateToProps = (state) => {
  return {
    files: state.uploads.files,
  }
};

function UploadPage({files, dispatch}) {
  const [downloads, setDownloads] = useState([]);
  const [showBeetsConsole, setShowBeetsConsole] = useState(false);
  const [showYoutubeDlDialog, setShowYoutubeDlDialog] = useState(false);
  const finishedTimeout = useRef();

  const {loading, error, data, refetch: refetchDownloadedFiles} = useQuery(DOWNLOADED_FILES_QUERY);
  const [deleteDownloadedFile] = useMutation(DELETE_DOWNLOADED_FILE);

  useEffect(() => {
    if (data) {
      setDownloads(data.downloadedFiles)
    }
  }, [data]);

  const patchFile = (uploadId, patch) => {
    dispatch({
      type: 'PATCH_UPLOADING_FILE',
      payload: {
        id: uploadId,
        ...patch,
      }
    });
  };

  const processQueue = () => {
    const uploadInProgress = !!files.find(uploadingFile => uploadingFile.uploading)
    const fileToUpload = files.find(uploadingFile => {
      return !uploadingFile.uploaded && !uploadingFile.error
    });

    if (!uploadInProgress && files.length > 0 && fileToUpload) {
      if (inProgress) {
        return;
      }

      inProgress = true;

      if (fileToUpload.file) {
        patchFile(fileToUpload.id, {uploading: true})

        const formData = new FormData();

        formData.append('file', fileToUpload.file)

        apiAxios({
          url: '/api/songs/upload',
          method: 'POST',
          onUploadProgress: (progress) => {
            patchFile(fileToUpload.id, {progress: progress.loaded / progress.total})
          },
          data: formData
        })
          .then(response => {
            patchFile(fileToUpload.id, {progress: 1, uploaded: true, uploading: false})

            if(response.data.song) {
              dispatch({
                type: 'APPEND_SONG',
                payload: response.data.song,
              })
            }
          })
          .catch(response => {
            patchFile(fileToUpload.id, {progress: 0, error: true, uploading: false})
          })
          .finally(() => {
            inProgress = false;
          })
      } else if (fileToUpload.url) {
        patchFile(fileToUpload.id, {uploading: true})

        apiAxios({
          url: '/api/songs/yt-dlp',
          method: 'POST',
          onUploadProgress: (progress) => {
            patchFile(fileToUpload.id, {progress: progress.loaded / progress.total})
          },
          data: {
            url: fileToUpload.url,
          }
        })
          .then(response => {
            const {data} = response;

            if (data.song) {
              patchFile(fileToUpload.id, {progress: 1, uploaded: true, uploading: false, song: data.song, response: data})
            } else {
              setDownloads([...downloads, {path: data.path, name: data.name}]);
              patchFile(fileToUpload.id, {progress: 1, uploaded: true, uploading: false, response: data})
            }
          })
          .catch(response => {
            patchFile(fileToUpload.id, {progress: 0, error: true, uploading: false})
          })
          .finally(() => {
            inProgress = false;
          })
      }
    } else {
      clearTimeout(finishedTimeout.current)
      finishedTimeout.current = setTimeout(() => {
        console.log('Finished')
        refetchDownloadedFiles()
      }, 1000);
    }
  };

  useEffect(() => {
    processQueue();
  }, [files])

  const onDrop = acceptedFiles => {
    dispatch({
      type: 'ADD_UPLOADING_FILES',
      payload: acceptedFiles.map(acceptedFile => {
        return {
          id: uuidv4(),
          file: acceptedFile,
          name: acceptedFile.name,
          progress: 0,
          uploading: false,
          uploaded: false,
        }
      })
    });
  };

  const addYoutubeDls = links => {
    dispatch({
      type: 'ADD_UPLOADING_FILES',
      payload: links.map(link => {
        return {
          id: uuidv4(),
          url: link,
          name: link,
          progress: 0,
          uploading: false,
          uploaded: false,
        }
      })
    });
  };

  const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop});
  const displayDropArea = isDragActive || files.length === 0;

  const {tabIndex, onClick: onClickUpload, ...rootProps} = getRootProps();

  const openBeets = () => setShowBeetsConsole(true);

  const clickDeleteFile = (path) => {
    deleteDownloadedFile({
      variables: {
        path: path
      }
    })
      .then(response => {
        setDownloads(response.data.deleteDownloadedFile)
      })
  };

  return (
    <>
      <Head/>
      <Flex flexDirection={'row'} flex={1}>
        <Flex flex={1} p="6" position={"relative"}>
          <HStack position={"absolute"} top={6} right={6}>
            <Button colorScheme={'green'} onClick={onClickUpload}>Upload</Button>
            <Button colorScheme={'green'} onClick={() => setShowYoutubeDlDialog(true)}>yt-dlp</Button>
            <Button colorScheme={"green"} onClick={openBeets}>Open Beets</Button>
          </HStack>
          <Flex as="main" flex={1} {...rootProps} flexDirection={'column'}>
            <Heading mb={4}>Uploads</Heading>
            <input {...getInputProps()} />
            {
              displayDropArea ?
                (
                  <Box flex={1} alignItems="center" justifyContent="center">
                    <p>Drop files here</p>
                  </Box>
                ) :
                (
                  <Box flex={1}>
                    {files.map(uploadingFile => {
                      return (
                        <UploadingFile key={uploadingFile.id} file={uploadingFile}/>
                      )
                    })}
                  </Box>
                )
            }
          </Flex>
        </Flex>
        <Flex width={300} borderLeftWidth={1} flexDirection={"column"} p={6}>
          <Heading mb={4}>Downloads</Heading>
          <List>
            {downloads.map(file => {
              return (
                <ListItem key={file.path}>
                  <Flex direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                    <Text>{file.name}</Text>
                    <CloseButton onClick={() => {
                      clickDeleteFile(file.path)
                    }}/>
                  </Flex>
                </ListItem>
              )
            })}
          </List>
        </Flex>
      </Flex>
      {showBeetsConsole && (
        <BeetsConsole onClose={() => {
          setShowBeetsConsole(false)
          refetchDownloadedFiles()
          refreshPlayer.current();
        }}/>
      )}
      <YoutubeDLDialog isOpen={showYoutubeDlDialog} onClose={() => setShowYoutubeDlDialog(false)} onSave={addYoutubeDls}/>
    </>
  )
}


export default connect(mapStateToProps)(UploadPage)
