import React, {useMemo} from 'react';
import CoverArt from "../../../components/CoverArt";
import {Box, Flex, Link, Text, Tooltip, useClipboard} from "@chakra-ui/react";
import {connect} from "react-redux";
import {motion} from "framer-motion"
import {MdCheck, MdLink} from "react-icons/md";

const mapStateToProps = (state) => {
  return {
    playingId: state.player.playingId,
    playlists: state.songs.playlists,
  }
};

function TitleColumn({cell, playingId, playlists}) {
  const songId = cell.row.original.id;
  const isPlaying = playingId === songId;

  const {soundcloud_url, source_url} = cell.row.original;

  const linkUrl = source_url || soundcloud_url;
  const {hasCopied: hasCopiedUrl, onCopy: onCopyUrl} = useClipboard(linkUrl)

  let inPlaylists = useMemo(() => {
    return playlists.filter(playlist => {
      if (playlist.id === 51) {
        return false;
      }

      let songIds = JSON.parse(playlist.song_ids) || []

      if (songIds) {
        return songIds.indexOf(songId) !== -1;
      }

      return false;
    })
  }, [songId, playlists]);

  const playlistsList = useMemo(() => {
    return inPlaylists.map(playlist => playlist.name).join(" - ")
  }, [inPlaylists])

  return (
    <Flex width={"100%"} alignItems={"center"}>
      <Box
        height={'32px'}
        width={"32px"}
        minWidth={"32px"}
        mr={2}
      >
        <Box position={'relative'}>
          <CoverArt
            songId={cell.row.original.id}
          />
          {isPlaying && (
            <Box position={'absolute'} top={0} left={0} right={0} bottom={0} p={1}>
              <svg viewBox={'0 0 64 64'}>
                <motion.rect
                  animate={{y: [64, 0, 64],}}
                  transition={{duration: 1, repeat: Infinity}}
                  fill={'white'}
                  height={64}
                  width={12}
                  x={6 / 3}
                  y={0}
                />
                <motion.rect
                  animate={{y: [64, 0, 64],}}
                  transition={{duration: 1.5, repeat: Infinity}}
                  fill={'white'}
                  x={(12 + 8 + 6) / 2}
                  y={0}
                  height={64}
                  width={12}
                />
                <motion.rect
                  animate={{y: [64, 0, 64],}}
                  transition={{duration: 0.8, repeat: Infinity}}
                  fill={'white'}
                  x={(12 + 8 + 12 + 8 + 6) / 2}
                  y={0}
                  height={64}
                  width={12}
                />
              </svg>
            </Box>
          )}
        </Box>
      </Box>
      <Flex
        overflow={'hidden'}
      >
        <Text
          isTruncated as={"span"}
          fontSize={'sm'}
          flex={1}
        >
          {cell.value}
        </Text>
        {inPlaylists.length > 0 && (
          <Tooltip label={playlistsList} aria-label={playlistsList} shouldWrapChildren={true}>
            <Box as={MdCheck} size="20px" color='blue.400' ml={2}/>
          </Tooltip>
        )}
        {!!linkUrl && (
          <Tooltip label={linkUrl} aria-label={linkUrl} shouldWrapChildren={true}>
            <Link as={MdLink} size="22px" color="gray.700" ml={2} isExternal href={linkUrl} onClick={onCopyUrl}/>
          </Tooltip>
        )}
      </Flex>
    </Flex>
  )
}

export default connect(mapStateToProps)(TitleColumn);
