import React from "react";
import {Provider} from "react-redux";
import {configureStore} from './Redux/store';
import theme from "./styles/theme";
import {ApolloClient, ApolloProvider, createHttpLink, InMemoryCache} from '@apollo/client';
import {setApiStore} from "./service/apiAxios";
import {setContext} from "@apollo/client/link/context";
import {PersistGate} from 'redux-persist/integration/react'
import App from "./App";
import { ChakraProvider } from "@chakra-ui/react"

const {store, persistor} = configureStore();

setApiStore(store);

const httpLink = createHttpLink({
  uri: '/api/graphql',
});

const authLink = setContext((_, {headers}) => {
  const state = store.getState();

  if (state.user.token) {
    return {
      headers: {
        ...headers,
        authorization: state.user.token ? `Bearer ${state.user.token}` : "",
      }
    }
  }

  return {}
});

const client = new ApolloClient({
  uri: '/api/graphql',
  cache: new InMemoryCache(),
  link: authLink.concat(httpLink),
});

export default function AppContainer() {
  return (
    <ApolloProvider client={client}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ChakraProvider theme={theme}>
            <App/>
          </ChakraProvider>
        </PersistGate>
      </Provider>
    </ApolloProvider>
  )
}
