import React, {useMemo, useState} from 'react';
import {MdQueueMusic} from "react-icons/md";
import PlayerIcon from "./PlayerIcon";
import {connect} from "react-redux";
import {FixedSizeList} from 'react-window';
import CoverArt from "../CoverArt";
import {Box, Flex, Text} from "@chakra-ui/react";

const mapStateToProps = (state) => ({
  songs: state.songs.items,
});

function QueueControl({queue, songs}) {
  const [hover, setHover] = useState(false);

  const filteredSongs = useMemo(() => {
    return queue.map(id => {
      return songs.find(song => song.id === id)
    })
  }, [songs, queue]);

  const Row = ({index, style}) => {
    const song = filteredSongs[index];

    return (
      <div style={style}>
        <Flex height={"60px"} alignItems={"center"} width={300 - 16} overflow={"hidden"} p={4}>
          <CoverArt minWidth={"40px"} width={"40px"} songId={song.id} mr={2}/>
          <Flex flex={1} flexDirection={"column"} overflow={"hidden"}>
            <Text fontSize={"xs"} isTruncated>{song.title}</Text>
            <Text fontSize={"xs"} isTruncated>{song.artist}</Text>
          </Flex>
        </Flex>
      </div>
    );
  };

  return (
    <Box
      position={"relative"}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <PlayerIcon icon={MdQueueMusic} mr={2}/>
      {hover && (
        <Box position={"absolute"} bottom={4} right={3} bg={"white"} borderWidth={1} height={400} width={300}>
          <FixedSizeList
            height={400}
            width={300}
            itemSize={50}
            itemCount={filteredSongs.length}
          >
            {Row}
          </FixedSizeList>
        </Box>
      )}
    </Box>
  )
}

export default connect(mapStateToProps)(QueueControl)
