import React, {useEffect, useState} from 'react';
import {Flex} from "@chakra-ui/react";
import VolumeControl from "./VolumeControl";
import ShuffleControl from "./ShuffleControl";
import SeekControl from "./SeekControl";
import PlayControl from "./PlayControl";
import TrackInfo from "./TrackInfo";
import {connect} from "react-redux";
import RepeatControl from "./RepeatControl";
import QueueControl from "./QueueControl";
import Sound from "react-sound";
import {gql, useMutation} from "@apollo/client";
import usePrevious from "../../hooks/usePrevious";
import useLocalStorageState from "../useLocalStorageState";
import Logger from "./Logger";
import LikeControl from "./LikeControl";
import PlaybackRateControl from "./PlaybackRateControl";

const mapStateToProps = (state) => ({
  playingId: state.player.playingId,
  playingQueue: state.player.playingQueue,
  profile: state.user.profile,
  token: state.user.token,
});

const INCREASE_SONG_PLAY_TIME = gql`
  mutation IncreaseSongPlayTime($id: Int!, $date: String!, $duration: Int!) {
    increaseSongPlayTime(id: $id, date: $date, duration: $duration) {
      id
      play_count
      play_time
    }
  }
`;

let sendingPlayDataTimeout = null;

function Player({playingId, playingQueue, dispatch, token, profile}) {
  const [playStatus, setPlayStatus] = useState('STOPPED');
  const [shuffle, setShuffle] = useState(false);
  const [repeat, setRepeat] = useLocalStorageState('none', 'player.repeat');
  const [url, setUrl] = useState(null);
  const [position, setPosition] = useState(0);
  const [playbackRate, setPlaybackRate] = useState(1);
  const [volume, setVolume] = useLocalStorageState(100, 'player.volume');
  const [muted, setMuted] = useLocalStorageState(false, 'player.muted');
  const [duration, setDuration] = useState(null);
  const [startTime, setStartTime] = useState(0);
  const [playDuration, setPlayDuration] = useState(0);
  const previousPlayingId = usePrevious(playingId);

  const [increaseSongPlayTime] = useMutation(INCREASE_SONG_PLAY_TIME);

  const isLoggedIn = token && profile;

  const sendPlayData = (songId) => {
    if (playDuration > 10 * 1000 && !sendingPlayDataTimeout && isLoggedIn) { // played more than 10 second
      sendingPlayDataTimeout = setTimeout(() => {
        increaseSongPlayTime({
          variables: {
            id: songId,
            date: (new Date(startTime)).toISOString(),
            duration: Math.round(playDuration / 1000),
          }
        })
          .then(response => {
            dispatch({
              type: 'PATCH_MULTIPLE_SONGS',
              payload: [response.data.increaseSongPlayTime],
            })
          })
        sendingPlayDataTimeout = null;
      }, 300)
    }
  };

  const playSong = (songId) => {
    setUrl(`/api/songs/stream/${songId}`);
    setPlayStatus('PLAYING');
    setPosition(0);
    setStartTime((new Date()).getTime());
    setPlayDuration(0);
  };

  useEffect(() => {
    if (playingId) {
      if (previousPlayingId) {
        sendPlayData(previousPlayingId);
      }
      playSong(playingId);
    }
  }, [playingId]);

  const onLoad = (event) => {
    setDuration(event.duration)
  };

  const onPlaying = (event) => {
    setPosition(event.position)
    setPlayDuration(playDuration + (event.position - position))
  };

  const onFinishedPlaying = (event) => {
    console.log('onFinishedPlaying')

    const currentIndex = playingQueue.findIndex(id => id === playingId);

    let nextSongId = null;

    if (repeat === 'one') {
      nextSongId = playingId
    } else if (repeat === 'all') {
      if (playingQueue[currentIndex + 1]) {
        nextSongId = playingQueue[currentIndex + 1]
      } else {
        nextSongId = playingQueue[0]
      }
    } else {
      nextSongId = playingQueue[currentIndex + 1];
    }

    if (nextSongId) {
      if (repeat === 'one' || nextSongId === playingId) {
        if (playingId) {
          sendPlayData(playingId);
        }
        playSong(nextSongId);
      } else {
        dispatch({
          type: 'SET_PLAYER_PLAYING_ID',
          payload: nextSongId
        });
      }
    } else {
      sendPlayData(playingId);
      setPlayStatus('STOPPED');
      setPosition(0);
      setStartTime((new Date()).getTime());
      setPlayDuration(0);
      dispatch({
        type: 'SET_PLAYER_PLAYING_ID',
        payload: null
      });
    }
  };

  return (
    <Flex borderWidth={1} p={3} alignItems={"center"}>
      {!!url && (
        <Sound
          url={url}
          position={position}
          playStatus={playStatus}
          playbackRate={playbackRate}
          volume={muted ? 0 : volume}
          onLoad={onLoad}
          onPlaying={onPlaying}
          onFinishedPlaying={onFinishedPlaying}
        />
      )}
      <TrackInfo
        isLoggedIn={isLoggedIn}
        currentSongId={playingId}
      />
      {isLoggedIn && (
        <LikeControl
          currentSongId={playingId}
        />
      )}
      <Flex align={"center"}>
        <RepeatControl
          repeat={repeat}
          setRepeat={setRepeat}
        />
        <ShuffleControl
          shuffle={shuffle}
          setShuffle={setShuffle}
        />
      </Flex>
      <PlayControl
        currentSongId={playingId}
        playingQueue={playingQueue}
        playStatus={playStatus}
        setPlayStatus={setPlayStatus}
      />
      <SeekControl
        duration={duration}
        position={position}
        setPosition={setPosition}
      />
      <VolumeControl
        volume={volume}
        setVolume={setVolume}
        muted={muted}
        setMuted={setMuted}
      />
      <PlaybackRateControl
        playbackRate={playbackRate}
        setPlaybackRate={setPlaybackRate}
      />
      {isLoggedIn && (
        <QueueControl
          queue={playingQueue}
        />
      )}
      <Logger
        currentSongId={playingId}
        playStatus={playStatus}
        volume={volume}
        muted={muted}
        duration={duration}
        position={position}
      />
    </Flex>
  )
}

export default connect(mapStateToProps)(Player)
