import {Link as RouterLink} from "react-router-dom";
import React from "react";
import Card from "../../components/Card";
import {Text, Link} from "@chakra-ui/react";

export default function PlaylistItem({playlist, ...props}) {
  const songIds = playlist.song_ids ? JSON.parse(playlist.song_ids) : [];

  const to = `/playlists/${playlist.id}`;

  return (
    <Card
      to={to}
      songs={songIds && songIds.map(id => ({id}))}
      {...props}>
      <Link
        isTruncated
        as={RouterLink}
        to={to}
      >
        <Text color={'gray.700'}>{playlist.name}</Text>
      </Link>
    </Card>
  )
}
