import React from 'react';
import {Field} from "formik";
import {FormControl, FormErrorMessage, FormLabel, Input} from "@chakra-ui/react";

export default function TextInput(props) {
  const {name, label, ...otherProps} = props;

  return (
    <Field name={name}>
      {({field, form}) => (
        <FormControl isInvalid={form.errors[name] && form.touched[name]}>
          <FormLabel>{label}</FormLabel>
          <Input {...field} {...otherProps}/>
          <FormErrorMessage>{form.errors[name]}</FormErrorMessage>
        </FormControl>
      )}
    </Field>
  );
}
