import React from "react";
import {connect} from "react-redux";
import Head from "../../components/Head";
import AutoSizer from "react-virtualized-auto-sizer";
import ArtistListing from "./ArtistListing";
import {useArtists} from "music-app-shared";

const mapStateToProps = state => {
  return {
    songs: state.songs.items,
    albums: state.songs.albums,
  };
}

function ArtistsPage({songs, albums}) {
  const artists = useArtists(songs, albums);

  return (
    <>
      <Head/>
      <AutoSizer>
        {({width, height}) => (
          <ArtistListing height={height} width={width} artists={artists}/>
        )}
      </AutoSizer>
    </>
  )
}


export default connect(mapStateToProps)(ArtistsPage)
