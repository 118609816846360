import React, {useMemo} from 'react';
import {connect} from "react-redux";

const mapStateToProps = (state) => {
  return {
    songs: state.songs.items,
  }
};

function Logger({currentSongId, songs, playStatus, volume, muted, duration, position}) {
  const song = useMemo(() => {
    return songs.find(song => song.id === currentSongId)
  }, [songs, currentSongId]);

  console.debug('song|' + JSON.stringify({
    song,
    playStatus,
    volume,
    muted,
    duration,
    position,
  }))

  return null;
}

export default connect(mapStateToProps)(Logger)
