import React, {useEffect} from "react";
import Head from "../../components/Head";
import {connect} from "react-redux";
import {usePlaylist} from "music-app-shared";
import {Box, Flex, Heading} from '@chakra-ui/react';
import SongRow from "./SongRow";
import Hashids from 'hashids'

const mapStateToProps = state => {
  return {
    playlists: state.songs.playlists,
    songs: state.songs.items,
  };
};

function SharePlaylist({route, match, dispatch, playlists, songs: librarySongs, ...props}) {
  const hashids = new Hashids('', 8);
  const id = hashids.decode(match.params.id)[0];
  const {
    playlist,
    songs,
  } = usePlaylist(id, playlists, librarySongs);

  useEffect(() => {
    dispatch({
      type: 'SET_PLAYING_QUEUE',
      payload: songs.map(song => song.id)
    })
  }, [songs]);

  return (
    <Flex p={10} alignItems={'center'} justifyContent={'center'} direction={'column'}>
      <Head title={playlist.name}/>
      <Heading mb={4}>{playlist.name}</Heading>
      <Box
        overflow={'hidden'}
        maxWidth={500}
        width={'100%'}
        alignItems={'center'}
        justifyContent={'center'}
        boxShadow={'md'}
        borderColor={'gray.100'}
        // borderWidth={1}
        borderRadius={'lg'}>
        {songs.map(song => {
          return (
            <SongRow key={song.id} song={song}/>
          )
        })}
      </Box>
    </Flex>
  )
}

export default connect(mapStateToProps)(SharePlaylist)
