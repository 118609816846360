import React, {useMemo, useState} from "react";
import {connect} from "react-redux";
import {usePopper} from 'react-popper';
import {gql, useMutation} from "@apollo/client";
import {useClickOutside} from "../../../components/useClickOutside";
import {MdCheck, MdClose} from "react-icons/md";
import {Box, Button, Flex, Stack, Text} from "@chakra-ui/react";
import _ from 'lodash';
import ContextMenuOption from "../../../components/ContextMenu/ContextMenuOption";

const EDIT_PLAYLIST = gql`
  mutation EditPlaylist($id: Int!, $name: String, $song_ids: String) {
    editPlaylist(id: $id, name: $name, song_ids: $song_ids) {
      id
      name
      song_ids
    }
  }
`;

const mapStateToProps = (state) => ({
  playingId: state.player.playingId,
  selectedSongs: state.app.selectedSongs,
  playlists: state.songs.playlists,
});

function SongRow({song, playingId, dispatch, children, selectedSongs, playlists, rows}) {
  const [hovering, setHovering] = useState(false);
  const [xOffset, setXOffset] = useState(0);
  const isSelected = selectedSongs.indexOf(song.id) !== -1;
  const [editPlaylist] = useMutation(EDIT_PLAYLIST);
  const [lastPressed, setLastPressed] = useState(0);
  const [lastPressedCount, setLastPressedCount] = useState(0);
  const [showPlaylists, setShowPlaylists] = useState(false);
  const [playlistYOffset, setPlaylistYOffset] = useState(0);
  const [playlistHeight, setPlaylistHeight] = useState(0);

  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const {styles, attributes} = usePopper(referenceElement, popperElement, {
    placement: 'left',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, xOffset],
        },
      },
    ],
  });

  const [menuOpen, setMenuOpen] = useState(false);

  const onClick = (event) => {
    event.preventDefault();
    if (event.nativeEvent.ctrlKey || event.nativeEvent.metaKey) {
      dispatch({
        type: 'SET_SELECTED_SONGS',
        payload: isSelected ? selectedSongs.filter(selectedSongId => selectedSongId !== song.id) : [...selectedSongs, song.id]
      })
    } else {
      const currentTimestamp = (new Date()).getTime();
      if (lastPressed > (new Date()).getTime() - 500) {
        dispatch({
          type: 'SET_PLAYER_PLAYING_ID',
          payload: parseInt(song.id)
        })

        dispatch({
          type: 'SET_PLAYING_QUEUE',
          payload: rows.map(row => row.original.id)
        })

        dispatch({
          type: 'SET_SELECTED_SONGS',
          payload: []
        })
      } else {
        dispatch({
          type: 'SET_SELECTED_SONGS',
          payload: [song.id]
        })
      }

      setLastPressed(currentTimestamp);
    }
  };

  const onContextMenu = (event) => {
    event.preventDefault();
    setMenuOpen(true);
    setXOffset(-event.nativeEvent.x)

    if (selectedSongs.indexOf(song.id) === -1) {
      setTimeout(() =>
        dispatch({
          type: 'SET_SELECTED_SONGS',
          payload: [song.id]
        }))
    }
  };

  useClickOutside(referenceElement, (event) => {
    if (!referenceElement.contains(event.target) && (popperElement && !popperElement.contains(event.target))) {
      setMenuOpen(false);
    }
  });

  const clickChangeSoundcloudUrl = () => {
    dispatch({
      type: 'SET_EDITING_SOUNDCLOUD_URL',
      payload: song.id
    })
  };

  const clickDelete = (event) => {
    if (event.nativeEvent.shiftKey) {

    } else {
      dispatch({
        type: 'SET_DELETING_SONGS',
        payload: isSelected ? selectedSongs : [song.id]
      })
    }
  };

  const clickEdit = (event) => {
    dispatch({
      type: 'SET_EDITING_SONGS',
      payload: isSelected ? selectedSongs : [song.id]
    })
  };

  const sortedPlaylists = useMemo(() => {
    return _.sortBy(
      _.filter(playlists, playlist => playlist.id !== 51),
      playlist => playlist.name.toLowerCase())
  }, [playlists]);

  const addToPlaylist = (playlist) => {
    let songIds = JSON.parse(playlist.song_ids) || [];

    if (selectedSongs.length > 0) {
      const containsAllSongs = selectedSongs.every(selectedSongId => songIds.indexOf(selectedSongId) !== -1);

      if (containsAllSongs) {
        // contains them all
        songIds = songIds.filter(songId => {
          return selectedSongs.indexOf(songId) === -1;
        })
      } else {
        selectedSongs.forEach(selectedSongId => {
          if (songIds.indexOf(selectedSongId) === -1) {
            songIds.push(selectedSongId)
          }
        })
      }
    } else {
      if (songIds.indexOf(song.id) !== -1) {
        songIds = songIds.filter(songId => songId !== song.id)
      } else {
        songIds.push(song.id)
      }
    }

    editPlaylist({
      variables: {
        id: playlist.id,
        song_ids: JSON.stringify(songIds)
      }
    })
      .then(response => {
        dispatch({
          type: 'PATCH_MULTIPLE_PLAYLISTS',
          payload: [response.data.editPlaylist],
        })
      })
  };

  const onMouseEnter = () => setHovering(true);
  const onMouseLeave = () => setHovering(false);

  const onMouseEnterPlaylists = () => {
    setShowPlaylists(true)
    const rect = popperElement.getBoundingClientRect();

    setPlaylistYOffset(-rect.y + 130);
    setPlaylistHeight(window.innerHeight - 200);
  };

  const isPlaying = song.id === playingId;

  return (
    <>
      <Flex
          dataKey={song.id}
        onClick={onClick}
        height={"40px"}
        borderBottomWidth={1}
        align={"center"}
        onContextMenu={onContextMenu}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        backgroundColor={isSelected ? 'gray.200' : hovering ? 'gray.300' : isPlaying ? 'gray.100' : 'white'}
        ref={setReferenceElement}
      >
        {children}
      </Flex>
      {menuOpen && (
        <Stack ref={setPopperElement} style={styles.popper}
               {...attributes.popper}
               zIndex={2}
               borderRadius={"lg"}
               borderWidth={1}
               backgroundColor={"white"}
               spacing={0}
               position={"relative"}
        >
          <ContextMenuOption borderRadius={0} onClick={clickEdit}>Edit</ContextMenuOption>
          <Box
            onMouseEnter={onMouseEnterPlaylists}
            onMouseLeave={() => setShowPlaylists(false)}
          >
            <ContextMenuOption borderRadius={0} width={'100%'}>Add To Playlist</ContextMenuOption>
            {showPlaylists && (
              <Stack
                zIndex={1000}
                borderRadius={"lg"}
                borderWidth={1}
                backgroundColor={"white"}
                position={"absolute"}
                spacing={0}
                left={'100%'}
                height={playlistHeight}
                top={playlistYOffset}
                bottom={0}
                overflowY={'scroll'}
              >
                <Stack spacing={0}>
                  {sortedPlaylists.map(playlist => {
                    const songIds = JSON.parse(playlist.song_ids);

                    return (
                      <ContextMenuOption borderRadius={0}
                              key={playlist.id}
                              onClick={() => addToPlaylist(playlist)}>
                        {playlist.name} {songIds && songIds.indexOf(song.id) !== -1 ? <Box as={MdCheck} size="20px" color='green.400'/> : <Box as={MdClose} size="20px" color='red.400'/>}
                      </ContextMenuOption>
                    )
                  })}
                </Stack>
              </Stack>
            )}
          </Box>
          <ContextMenuOption borderRadius={0} onClick={clickDelete}>Delete</ContextMenuOption>
          <ContextMenuOption borderRadius={0} onClick={clickChangeSoundcloudUrl}>Change SC URL</ContextMenuOption>
        </Stack>
      )}
    </>
  )
}

export default connect(mapStateToProps)(SongRow)
