import React from 'react';
import {MdShuffle} from "react-icons/md";
import PlayerIcon from "./PlayerIcon";
import {connect} from "react-redux";
import constants from "../../styles/constants";

const mapStateToProps = (state) => ({
  originalPlayingQueue: state.player.originalPlayingQueue,
  playingQueue: state.player.playingQueue,
});

function ShuffleControl({shuffle, setShuffle, dispatch, originalPlayingQueue}) {
  const onClick = (evt) => {
    evt.preventDefault();

    if (shuffle) {
      console.log(originalPlayingQueue)
      // set not shuffled
      dispatch({
        type: 'SET_PLAYING_QUEUE',
        payload: originalPlayingQueue
      })
    } else {
      dispatch({
        type: 'SHUFFLE_PLAYING_QUEUE'
      })
    }
    setShuffle(!shuffle);
  };

  return (
    <PlayerIcon icon={MdShuffle} mr={2} color={shuffle ? constants.themeColor : undefined} onClick={onClick}/>
  )
}

export default connect(mapStateToProps)(ShuffleControl)
