export default (state = {
  playingId: null,
  playingQueue: [],
  originalPlayingQueue: [],
}, action) => {
  switch (action.type) {
    case 'SET_PLAYER_PLAYING_ID':
      return {
        ...state,
        playingId: action.payload,
        position: 0,
        playStatus: 'PLAYING',
        url: `/api/songs/stream/${action.payload}`,
      };
    case 'SET_PLAYING_QUEUE':
      return {
        ...state,
        playingQueue: action.payload,
        originalPlayingQueue: action.payload,
      };
    case 'SHUFFLE_PLAYING_QUEUE':
      return {
        ...state,
        playingQueue: shuffle([...state.playingQueue]),
      };
    default:
      return state;
  }
}

function shuffle(a) {
  var j, x, i;
  for (i = a.length - 1; i > 0; i--) {
    j = Math.floor(Math.random() * (i + 1));
    x = a[i];
    a[i] = a[j];
    a[j] = x;
  }
  return a;
}
