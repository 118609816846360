import React from "react";
import RootPage from './pages/RootPage';
import SongsPage from './pages/SongsPage';
import UploadPage from './pages/UploadPage';
import AlbumsShowPage from './pages/AlbumsShowPage';
import AlbumsPage from './pages/AlbumsPage';
import PlaylistsShowPage from './pages/PlaylistsShowPage';
import PlaylistsPage from './pages/PlaylistsPage';
import ArtistsPage from './pages/ArtistsPage';
import ArtistsShowPage from './pages/ArtistsShowPage';
import GenresPage from './pages/GenresPage';
import GenresShowPage from './pages/GenresShowPage';
import SearchPage from './pages/SearchPage';
import LoginPage from './pages/LoginPage';
import SharePlaylist from './pages/SharePlaylist';
import AppContainer from "./components/layout/AppContainer";
import SharedAppContainer from "./pages/SharePlaylist/SharedAppContainer";

const routes = [
  {
    public: true,
    routes: [
      {
        path: '/login',
        component: LoginPage,
      }
    ]
  },
  {
    layout: SharedAppContainer,
    public: true,
    routes: [
      {
        path: '/share/playlist/:id',
        component: SharePlaylist,
      }
    ]
  },
  {
    layout: AppContainer,
    routes: [
      {
        path: "/playlists/:id",
        exact: true,
        component: PlaylistsShowPage,
      },
      {
        path: "/playlists",
        exact: true,
        component: PlaylistsPage,
      },
      {
        path: "/albums/:id",
        exact: true,
        component: AlbumsShowPage,
      },
      {
        path: "/albums",
        exact: true,
        component: AlbumsPage,
      },
      {
        path: "/artists/:name",
        exact: true,
        component: ArtistsShowPage,
      },
      {
        path: "/artists",
        exact: true,
        component: ArtistsPage,
      },
      {
        path: "/songs",
        exact: true,
        component: SongsPage,
      },
      {
        path: "/genres/:name",
        exact: true,
        component: GenresShowPage,
      },
      {
        path: "/genres",
        exact: true,
        component: GenresPage,
      },
      {
        path: "/upload",
        exact: true,
        component: UploadPage,
      },
      {
        path: "/search",
        component: SearchPage,
      },
      {
        path: "/search/:searchText",
        component: SearchPage,
      },
      {
        path: "/login",
        exact: true,
        component: LoginPage,
      },
      {
        exact: true,
        path: "/",
        component: RootPage,
      },
    ]
  },
];

export default routes;

function extractRoutesToList(list, routes) {
  routes.forEach(route => {
    const {routes, ...slicedRoute} = route;
    list.push(slicedRoute);

    if (routes) {
      extractRoutesToList(list, routes)
    }
  })
}

export function getFlattenRoutes() {
  return routes.map(routeGroup => {
    const flattenedRoutes = [];

    extractRoutesToList(flattenedRoutes, routeGroup.routes)

    return {
      ...routeGroup,
      routes: flattenedRoutes,
    };
  });
}
