import React from "react";
import GenreItem from "./GenreItem";
import AutoSizer from "react-virtualized-auto-sizer";
import CardListing from "../../components/layout/CardListing";

export default function GenreListing({genres}) {
  return (
    <AutoSizer>
      {({width, height}) => (
        <CardListing
          width={width}
          height={height}
          items={genres}
          cardRenderer={item => <GenreItem genre={item}/>}
        />
      )}
    </AutoSizer>
  )
}
