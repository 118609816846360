import React, {useMemo} from 'react';
import {connect} from "react-redux";
import CoverArt from "../CoverArt";
import {Link as RouterLink} from "react-router-dom";
import {Flex, Link, Stack, Text} from "@chakra-ui/react";

const mapStateToProps = (state) => {
  return {
    songs: state.songs.items,
  }
};

function TrackInfo({currentSongId, songs, isLoggedIn}) {
  const song = useMemo(() => {
    return songs.find(song => song.id === currentSongId)
  }, [songs, currentSongId]);

  return (
    <Flex alignItems={"center"} mr={4} width={"200px"}>
      {song && (
        <>
          <CoverArt key={currentSongId} borderWidth={1} height={"35px"} width={"35px"} mr={2} songId={currentSongId}/>
          <Stack spacing={0} flex={1} overflow={"hidden"}>
            {isLoggedIn && (
              <Link
                display={"block"}
                color={"gray.600"}
                fontWeight={'600'}
                fontSize={"xs"}
                isTruncated
                as={RouterLink}
                to={`/artists/${encodeURIComponent(song.artist)}`}
              >{song.artist}</Link>
            )}
            {!isLoggedIn && (
              <Text
                display={"block"}
                color={"gray.600"}
                fontWeight={'600'}
                fontSize={"xs"}
              >{song.artist}</Text>
            )}
            <Text as="span" display={"block"} fontSize={"xs"} isTruncated>{song.title}</Text>
          </Stack>
        </>
      )}
    </Flex>
  );
}

export default connect(mapStateToProps)(TrackInfo)
