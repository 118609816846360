import React from 'react';
import CoverArt from "./CoverArt";
import {AspectRatio, Flex, Box} from "@chakra-ui/react";

function MultipleCoverArt({songs, ...props}) {
  const ids = songs ? songs.map(song => song.id) : [];

  const fourIds = ids.slice(0, 4);

  return (
    <AspectRatio ratio={1} {...props}>
      <Box height={'100%'} width={'100%'}>
        <Flex height={'100%'} width={'100%'} wrap={"wrap"} bg={'gray.200'} align={'flex-start'} justify={'flex-start'}>
          {fourIds.map(id => {
            return (
              <Flex width={'50%'} height={'50%'} key={id}>
                <CoverArt
                  flex={1}
                  songId={id}
                />
              </Flex>
            )
          })}
        </Flex>
      </Box>
    </AspectRatio>
  )
}

export default MultipleCoverArt;
