import React from 'react';
import {useClickOutside} from "../useClickOutside";
import {Box} from "@chakra-ui/react";

function ContextMenu({children, setOpen, popperElement, setPopperElement, styles, attributes}) {
  useClickOutside(popperElement ? {current: popperElement} : null, () => setOpen(false));

  return (
    <Box
      ref={setPopperElement}
      style={styles.popper}
      zIndex={2}
      borderRadius={"lg"}
      borderWidth={1}
      borderColor={'#efefef'}
      backgroundColor={"white"}
      spacing={0}
      position={"relative"}
      boxShadow={'md'}
    >
      {React.Children.map(children, child => React.cloneElement(child, {setOpen}))}
    </Box>
  )
}

export default ContextMenu;
