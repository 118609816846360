import React, {useState, useMemo} from 'react';
import CoverArt from "../../components/CoverArt";
import PlayingSvg from "./PlayingSvg";
import {Box, Flex, Text} from '@chakra-ui/react';
import {connect} from "react-redux";
import {MdCheck} from "react-icons/md";

const mapStateToProps = (state) => {
  return {
    playingId: state.player.playingId,
  }
};

function SongRow({song, playingId, dispatch}) {
  const isPlaying = song.id === playingId;

  const onClick = () => {
    dispatch({
      type: 'SET_PLAYER_PLAYING_ID',
      payload: parseInt(song.id)
    })
  };

  const [hover, setHover] = useState(false);

  return (
    <Flex
      p={2}
      px={4}
      backgroundColor={isPlaying ? 'gray.200' : 'white'}
      onClick={onClick}
      _hover={{bg: "gray.100", cursor: 'pointer'}}
      alignItems={'center'}
      justifyContent={'space-between'}
    >
      <Flex
        alignItems={'center'}
      >
        <Box
          height={'32px'}
          width={"32px"}
          minWidth={"32px"}
          mr={2}
        >
          <Box position={'relative'}>
            <CoverArt
              songId={song.id}
            />
            {isPlaying && (
              <Box position={'absolute'} top={0} left={0} right={0} bottom={0} p={1}>
                <PlayingSvg/>
              </Box>
            )}
          </Box>
        </Box>
        <Text
          isTruncated as={"span"}
          fontSize={'sm'}>{song.title}</Text>
      </Flex>

      <Text
        color={"gray.500"}
        letterSpacing="wide"
        isTruncated
        fontSize={'sm'}
      >
        {song.artist}
      </Text>
    </Flex>
  )
}

export default connect(mapStateToProps)(SongRow);
