import React, {useState} from "react";
import {connect} from "react-redux";
import Head from "../../components/Head";
import SongsListing from "../SongsPage/SongsListing";
import {useSearch} from 'music-app-shared';

const mapStateToProps = state => {
  return {
    songs: state.songs.items,
    albums: state.songs.albums,
  };
}

function SearchPage({songs: librarySongs, albums: libraryAlbums, dispatch, match}) {
  const {searchText = ''} = match.params;

  const {songs} = useSearch(searchText, librarySongs, libraryAlbums);

  const [sortBy, setSortBy] = useState([
    {
      id: 'title',
      desc: false
    }
  ]);

  return (
    <>
      <Head/>
      <SongsListing
        key={searchText}
        dispatch={dispatch}
        songs={songs}
        sortBy={sortBy}
        setSortBy={setSortBy}
      />
    </>
  )
}


export default connect(mapStateToProps)(SearchPage)
