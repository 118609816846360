import {combineReducers} from 'redux';

import app from './app'
import user from './user'
import songs from './songs'
import player from './player'
import uploads from './uploads'

export default combineReducers({
	app,
	user,
	songs,
	player,
	uploads,
})
