import LogoSvg from "../LogoSvg";
import React, {useState} from "react";
import {Link as RouterLink, useHistory, useLocation, useRouteMatch} from "react-router-dom";
import {connect} from "react-redux";
import {Avatar, Box, Flex, Input, InputGroup, InputLeftElement, Link} from "@chakra-ui/react";
import {SearchIcon} from '@chakra-ui/icons'
import constants from "../../styles/constants";
import {refreshPlayer} from "./AppContainer";

const mapStateToProps = state => ({
  profile: state.user.profile,
});

const WrappedTabLink = ({href, title, children, ...props}) => {
  const match = useRouteMatch(href);

  return (
    <Box borderBottomWidth={'2px'} borderColor={match ? constants.themeColor : 'transparent'} mb={'-1px'} bg={'transparent'}>
      <Link
        display={"block"}
        p={3}
        px={8}
        as={RouterLink}
        to={href}
        color={match ? constants.themeColor : "gray.600"}
        letterSpacing="wide"
        fontWeight="600"
        _hover={{textDecoration: 'none', color: match ? constants.themeColor : 'gray.500'}}
      >
        {title || children}
      </Link>
    </Box>
  )
};

let searchTimeout = null;

function Header({dispatch, profile}) {
  const history = useHistory();
  const location = useLocation();
  const [currentSearchText, setCurrentSearchText] = useState('');

  const onSearchChange = (event) => {
    const value = event.target.value;

    clearTimeout(searchTimeout);

    setCurrentSearchText(value);

    searchTimeout = setTimeout(() => {
      const newUrl = `/search/${encodeURIComponent(value)}`;

      if (location.pathname !== newUrl) {
        history.push(newUrl);
      }
    }, 400);
  }

  return (
    <Flex borderBottomWidth={1} flexDirection={"column"}>
      <Flex p={4} alignItems={"center"}>
        <Box mr={4}>
          <LogoSvg height={40} onClick={() => window.refreshPlayer()} cursor={'pointer'}/>
        </Box>
        <Flex flex={1} align={"center"} justifyContent={"center"}>
          <InputGroup flex={1} mr={4} maxWidth={500}>
            <InputLeftElement children={<SearchIcon name="search" color="gray.300"/>}/>
            <Input type="phone" placeholder="Search..." value={currentSearchText} onChange={onSearchChange}/>
          </InputGroup>
        </Flex>
        <Avatar size={"sm"} name={profile.name}/>
      </Flex>
      <Flex>
        <WrappedTabLink title="Playlists" href="/playlists"/>
        <WrappedTabLink title="Artists" href="/artists"/>
        <WrappedTabLink title="Albums" href="/albums"/>
        <WrappedTabLink title="Songs" href="/songs"/>
        <WrappedTabLink title="Genres" href="/genres"/>
        <WrappedTabLink title="Upload" href="/upload"/>
      </Flex>
    </Flex>
  )
}

export default connect(mapStateToProps)(Header)

