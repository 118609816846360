import React, {useMemo} from 'react';
import {MdFavorite} from "react-icons/md";
import PlayerIcon from "./PlayerIcon";
import {connect} from "react-redux";
import {gql, useMutation} from "@apollo/client";
import {Flex} from "@chakra-ui/react";

const SET_SONG_LIKED = gql`
  mutation SetSongLiked($id: Int!, $liked_at: Int!) {
    setSongLiked(id: $id, liked_at: $liked_at) {
      id
      liked_at
    }
  }
`;

const mapStateToProps = (state) => {
  return {
    songs: state.songs.items,
  }
};

function LikeControl({songs, currentSongId, dispatch}) {
  const [setSongLiked] = useMutation(SET_SONG_LIKED);

  const song = useMemo(() => {
    return songs.find(song => song.id === currentSongId)
  }, [songs, currentSongId]);

  const isLiked = !!song?.liked_at;

  const pressFavourite = () => {
    setSongLiked({
      variables: {
        id: currentSongId,
        liked_at: isLiked ? 0 : Math.round((new Date().getTime() / 1000)),
      }
    })
      .then(response => {
        dispatch({
          type: 'PATCH_MULTIPLE_SONGS',
          payload: [response.data.setSongLiked],
        })
      })
  };

  return (
    <Flex alignItems={"center"}>
      <PlayerIcon icon={MdFavorite} onClick={pressFavourite} mr={2} color={isLiked ? 'red.400' : null}/>
    </Flex>
  )
}

export default connect(mapStateToProps)(LikeControl)
