import React, {useState} from "react";
import Head from "../../components/Head";
import SongsListing from "../SongsPage/SongsListing";
import {Box, Button, Flex, Heading, useClipboard} from "@chakra-ui/react";
import {connect} from "react-redux";
import MultipleCoverArt from "../../components/MultipleCoverArt";
import {usePlaylist} from "music-app-shared";
import {MdMoreVert} from "react-icons/md";
import ContextMenu from "../../components/ContextMenu";
import ContextMenuOption from "../../components/ContextMenu/ContextMenuOption";
import ContextMenuContainer from "../../components/ContextMenu/ContextMenuContainer";
import {gql, useMutation} from "@apollo/client";
import {useHistory} from "react-router-dom";
import EditPlaylistDialog from "./EditPlaylistDialog";
import Hashids from 'hashids'
import _ from 'lodash';

const DELETE_PLAYLIST = gql`
  mutation DeletePlaylist($id: Int!) {
    deletePlaylist(id: $id)
  }
`;

const EDIT_PLAYLIST = gql`
  mutation EditPlaylist($id: Int!, $name: String, $song_ids: String) {
    editPlaylist(id: $id, name: $name, song_ids: $song_ids) {
      id
      name
      song_ids
    }
  }
`;

const mapStateToProps = state => {
  return {
    playlists: state.songs.playlists,
    songs: state.songs.items,
  };
};

function PlaylistsShowPage({match, dispatch, playlists, songs: librarySongs}) {
  const {
    playlist,
    songs,
    defaultSortBy,
  } = usePlaylist(match.params.id, playlists, librarySongs);
  const history = useHistory();

  const [deletePlaylist] = useMutation(DELETE_PLAYLIST);
  const [editPlaylist] = useMutation(EDIT_PLAYLIST);

  const clickDeletePlaylist = () => {
    deletePlaylist({
      variables: {
        id: playlist.id,
      }
    })
      .then(() => {
        history.push('/playlists');

        setTimeout(() => {
          dispatch({
            type: 'DELETE_PLAYLIST',
            payload: playlist,
          })
        }, 100)
      });
  };

  const clickEditPlaylist = () => {
    dispatch({
      type: 'SET_EDITING_PLAYLIST_ID',
      payload: playlist.id,
    })
  };

  console.log(playlist.song_ids)

  const [sortBy, setSortBy] = useState(defaultSortBy || []);

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    if (result.source.index === result.destination.index) {
      return;
    }

    const sourceIndex = result.source.index - 2;
    const destinationIndex = result.destination.index - 2;

    const songIds = _.map(songs, song => song.id)

    const original = songIds[sourceIndex];

    songIds.splice(sourceIndex, 1);
    songIds.splice(destinationIndex, 0, original);

    const songIdsString = JSON.stringify(songIds)

    dispatch({
      type: 'PATCH_MULTIPLE_PLAYLISTS',
      payload: [{
        id: playlist.id,
        song_ids: songIdsString
      }],
    })

    editPlaylist({
      variables: {
        id: playlist.id,
        song_ids: songIdsString,
      }
    })
  }

  const hashids = new Hashids('', 8);

  const {hasCopied, onCopy} = useClipboard(`${window.location.origin}/share/playlist/${hashids.encode(playlist.id)}`);

  return (
    <>
      <Head title={playlist.name}/>
      <SongsListing
        defaultSortBy={defaultSortBy}
        songs={songs}
        dispatch={dispatch}
        sortBy={sortBy}
        setSortBy={setSortBy}
        onDragEnd={!playlist.system ? onDragEnd : null}
        showAdded={playlist.system && playlist.id === 'recent'}
        Header={(
          <Flex p={8}>
            <MultipleCoverArt width={230} height={230} songs={songs} borderRadius={"lg"} overflow={"hidden"}/>
            <Flex ml={4} flexDirection={'row'} justifyContent={'space-between'} flex={1}>
              <Heading mb={4}>{playlist.name}</Heading>
              <Button onClick={onCopy}>{hasCopied ? 'Copied' : 'Share'}</Button>
              <Box>
                {!playlist.system && (
                  <ContextMenuContainer view={<Box as={MdMoreVert} size="22px" color="gray.700" position={'relative'}/>}>
                    <ContextMenu>
                      <ContextMenuOption label={'Edit'} onClick={clickEditPlaylist}/>
                      <ContextMenuOption label={'Delete'} onClick={clickDeletePlaylist}/>
                    </ContextMenu>
                  </ContextMenuContainer>
                )}
              </Box>
            </Flex>
          </Flex>
        )}
      />
      <EditPlaylistDialog/>
    </>
  )
}

export default connect(mapStateToProps)(PlaylistsShowPage)
