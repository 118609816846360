import React from 'react';
import {MdPause, MdPlayArrow, MdSkipNext, MdSkipPrevious} from "react-icons/md";
import PlayerIcon from "./PlayerIcon";
import {connect} from "react-redux";
import {Flex} from "@chakra-ui/react";

function PlayControl({playStatus, setPlayStatus, playingQueue, currentSongId, dispatch}) {
  const PlayIcon = playStatus === 'PLAYING' ? MdPause : MdPlayArrow;

  const onClick = () => {
    setPlayStatus(playStatus === 'PLAYING' ? 'PAUSED' : 'PLAYING')
  };

  const clickPrev = () => {
    if (playingQueue.length > 0) {
      const currentIndex = playingQueue.findIndex(id => id === currentSongId);

      let prevIndex = currentIndex - 1;

      if (prevIndex < 0) {
        prevIndex = playingQueue.length - 2 - prevIndex;
      }

      const songId = playingQueue[prevIndex];

      dispatch({
        type: 'SET_PLAYER_PLAYING_ID',
        payload: songId
      })
    }
  };

  const clickNext = () => {
    if (playingQueue.length > 0) {
      const currentIndex = playingQueue.findIndex(id => id === currentSongId);

      const nextIndex = (currentIndex + 1) % (playingQueue.length - 1);

      const songId = playingQueue[nextIndex];

      dispatch({
        type: 'SET_PLAYER_PLAYING_ID',
        payload: songId
      })
    }
  };

  return (
    <Flex alignItems={"center"}>
      <PlayerIcon icon={MdSkipPrevious} onClick={clickPrev} mr={2}/>
      <PlayerIcon icon={PlayIcon} onClick={onClick} mr={2}/>
      <PlayerIcon icon={MdSkipNext} onClick={clickNext} mr={4}/>
    </Flex>
  )
}

export default connect()(PlayControl)
