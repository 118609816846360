import React, {useState} from "react";
import {AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Button, Textarea} from "@chakra-ui/react";

function YoutubeDLDialog({isOpen, onSave, onClose}) {
  const cancelRef = React.useRef();
  const [text, setText] = useState('');

  const handleInputChange = (event) => {
    setText(event.target.value)
  };

  const clickSave = () => {
    let links = text.split('\n');
    links = links.map(link => link.trim())
    links = links.filter(link => !!link)
    links = links.filter(link => link.indexOf('http') !== -1)
    links = links.map(link => {
      if(link.indexOf('&list=') !== -1) {
        link = link.split('&list=')[0]
      }

      return link;
    })

    onSave(links)

    setText('')
    onClose()
  };

  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={() => null}
      size={'6xl'}
    >
      <AlertDialogOverlay/>
      <AlertDialogContent>
        <AlertDialogHeader fontSize="lg" fontWeight="bold">
          yt-dlp
        </AlertDialogHeader>
        <AlertDialogBody>
          <Textarea
            value={text}
            onChange={handleInputChange}
            placeholder="Urls separated by newline"
            size="sm"
            minHeight={'xl'}
          />
        </AlertDialogBody>
        <AlertDialogFooter>
          <Button ref={cancelRef} onClick={onClose}>
            Cancel
          </Button>
          <Button colorScheme="green" onClick={clickSave} ml={3}>
            Download
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}

export default YoutubeDLDialog;
