import {Formik} from "formik";
import * as yup from "yup";
import TextInput from "../../components/TextInput";
import React, {useMemo, useRef} from "react";
import {connect} from "react-redux";
import {AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Button} from "@chakra-ui/react";
import {gql, useMutation} from "@apollo/client";

const EDIT_PLAYLIST = gql`
  mutation EditPlaylist($id: Int!, $name: String, $song_ids: String) {
    editPlaylist(id: $id, name: $name, song_ids: $song_ids) {
      id
      name
      song_ids
    }
  }
`;

const mapStateToProps = (state) => {
  return {
    editingPlaylistId: state.app.editingPlaylistId,
    playlists: state.songs.playlists,
  }
};

function EditPlaylistDialog({editingPlaylistId, playlists, dispatch}) {
  const playlist = useMemo(() => {
    return playlists.find(playlist => playlist.id === editingPlaylistId)
  }, [editingPlaylistId, playlists]);
  const cancelRef = useRef();
  const [editPlaylist] = useMutation(EDIT_PLAYLIST);

  const clickClose = () => {
    dispatch({
      type: 'SET_EDITING_PLAYLIST_ID',
      payload: null,
    })
  };

  const onSubmit = (values) => {
    return editPlaylist({
      variables: {
        id: playlist.id,
        name: values.name,
      }
    })
      .then(response => {
        dispatch({
          type: 'PATCH_MULTIPLE_PLAYLISTS',
          payload: [response.data.editPlaylist],
        })

        clickClose();
      })
  };

  return (
    <AlertDialog
      isOpen={!!playlist}
      leastDestructiveRef={cancelRef}
      onClose={clickClose}
    >
      <AlertDialogOverlay/>
      <AlertDialogContent>
        <AlertDialogHeader fontSize="lg" fontWeight="bold">
          Create Playlist
        </AlertDialogHeader>
        <Formik
          onSubmit={onSubmit}
          enableReinitialize={true}
          initialValues={{
            name: playlist?.name,
          }}
          validationSchema={yup.object({
            name: yup.string().required()
          })}
        >
          {form => {
            return (
              <>
                <AlertDialogBody>
                  <TextInput label="Name" name="name"/>
                </AlertDialogBody>
                <AlertDialogFooter>
                  <Button ref={cancelRef} onClick={clickClose}>
                    Cancel
                  </Button>
                  <Button colorScheme="green" onClick={form.submitForm} ml={3}>
                    Update
                  </Button>
                </AlertDialogFooter>
              </>
            )
          }}
        </Formik>
      </AlertDialogContent>
    </AlertDialog>
  )
}


export default connect(mapStateToProps)(EditPlaylistDialog)
