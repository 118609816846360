import React from 'react';
import {motion} from "framer-motion";

function PlayingSvg() {
  return (
    <svg viewBox={'0 0 64 64'}>
      <motion.rect
        animate={{y: [64, 0, 64],}}
        transition={{duration: 1, repeat: Infinity}}
        fill={'white'}
        height={64}
        width={12}
        x={6 / 3}
        y={0}
      />
      <motion.rect
        animate={{y: [64, 0, 64],}}
        transition={{duration: 1.5, repeat: Infinity}}
        fill={'white'}
        x={(12 + 8 + 6) / 2}
        y={0}
        height={64}
        width={12}
      />
      <motion.rect
        animate={{y: [64, 0, 64],}}
        transition={{duration: 0.8, repeat: Infinity}}
        fill={'white'}
        x={(12 + 8 + 12 + 8 + 6) / 2}
        y={0}
        height={64}
        width={12}
      />
    </svg>
  )
}

export default PlayingSvg;
